import { useState } from 'react'
import { toast } from 'react-toastify'

import { useWebSocket } from 'ahooks'
import { useTranslate } from 'config/i18n'
import { createWSUrl } from 'config/ws'
import { useManualWSConnect } from 'hooks/useManualWSConnect'
import { isOpti } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { updateSystemOptiWebSocketEndPoint } from 'modules/systemUpdating/ws/endpoints'
import { transformResponseToCamelCase } from 'packages/api'

export const useUpdatingSystemOptiWS = () => {
  const [isUpdatingWS, setIsUpdatingWS] = useState(false)
  const currentStream = useCurrentStream((state) => state.currentStream)

  const translate = useTranslate()
  const url = createWSUrl(updateSystemOptiWebSocketEndPoint.updating())

  const { connect, disconnect, readyState } = useWebSocket(url, {
    onMessage: (event) => {
      try {
        const { status } = transformResponseToCamelCase(event.data) ?? {}
        if (status) {
          setIsUpdatingWS(true)
        }
      } catch {
        toast.error(translate('wsErrors.messageRead'))
      }
    },
    manual: true,
  })

  useManualWSConnect(url, readyState, connect, disconnect, isOpti(currentStream))

  return { isUpdatingWS }
}
