import { axios } from 'config/axios'
import { GetOneResponse } from 'interfaces/api.interfaces'
import { TokenResponse } from 'interfaces/auth.interfaces'

import { authEndpoints } from './endpoints'
import { AuthTokens, ForgotData, LoginData, ResendTwoFactorCodeData, TwoFactorLoginData } from './interfaces'

const login = async (form: LoginData): GetOneResponse<TokenResponse> => {
  const { data } = await axios.request<TokenResponse>({
    method: 'POST',
    url: authEndpoints.login(),
    data: {
      email: form.email.toLowerCase(),
      password: form.password,
    },
  })
  return data
}

const resetPassword = async (form: ForgotData): Promise<void> => {
  await axios.request({
    method: 'POST',
    url: authEndpoints.resetPassword(),
    data: {
      email: form.email.toLowerCase(),
    },
  })
}

const logoutInform = async (): Promise<void> => {
  await axios.request({
    method: 'GET',
    url: authEndpoints.logoutInform(),
  })
}

const resendTwoFactorCode = async (form: ResendTwoFactorCodeData): Promise<void> => {
  await axios.request({
    method: 'POST',
    url: authEndpoints.resendTwoFactorCode(),
    data: {
      email: form.email.toLowerCase(),
    },
  })
}

const twoFactorLogin = async (form: TwoFactorLoginData): Promise<TokenResponse> => {
  const { data } = await axios.request({
    method: 'POST',
    url: authEndpoints.twoFactorLogin(),
    data: {
      email: form.email.toLowerCase(),
      code: form.code,
    },
  })
  return data
}

const checkTwoFactorCode = async (): Promise<void> => {
  const { data } = await axios.request({
    method: 'GET',
    url: authEndpoints.checkTwoFactorCode(),
  })
  return data
}

const getAuthSsoTokens = async (code: string): Promise<AuthTokens> => {
  const { data } = await axios.request<AuthTokens>({
    method: 'POST',
    url: authEndpoints.getAuthSsoTokens(),
    data: {
      code,
    },
  })
  return data
}

export const authApi = {
  login,
  resetPassword,
  logoutInform,
  resendTwoFactorCode,
  twoFactorLogin,
  checkTwoFactorCode,
  getAuthSsoTokens,
}
