import { useState } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg'
import cx from 'clsx'
import { NavLink } from 'components/template/NavLink'
import { NavTitle } from 'components/template/NavTitle'
import { useTranslate } from 'config/i18n'
import { Routes } from 'routes/routes'
import { SlideToggle, SlideToggleTypes } from 'ui/SlideToggle'

import classes from './NavMenuPpds.module.scss'

export const NavMenuPpds = () => {
  const [isLoadDataOpen, setIsLoadDataOpen] = useState(false)
  const translate = useTranslate()

  const toggleMenuOpen = () => setIsLoadDataOpen((prevState) => !prevState)

  return (
    <>
      <NavTitle
        className={cx('menuItem', classes.disabled)}
        icon={<ArrowIcon />}
        isActive={isLoadDataOpen}
        onClick={toggleMenuOpen}
      >
        {translate('menu.masterData')}
      </NavTitle>
      <SlideToggle classNameWrap={classes.subMenu} open={isLoadDataOpen} type={SlideToggleTypes.NoTransform}>
        <NavLink className="menuItem" path={Routes.PpdsMaterials}>
          {translate('menu.materials')}
        </NavLink>
        <NavLink className="menuItem" path={Routes.PpdsResources}>
          {translate('menu.resources')}
        </NavLink>
        <NavLink className="menuItem" path={Routes.PpdsRecipes}>
          {translate('menu.recipes')}
        </NavLink>
        <NavLink className="menuItem" path={Routes.PpdsMaterialsFamily}>
          {translate('menu.materialsFamily')}
        </NavLink>
        <NavLink className="menuItem" path={Routes.PpdsResourceFamilies}>
          {translate('menu.resourcesFamily')}
        </NavLink>
      </SlideToggle>
      <NavLink className="menuItem" path={Routes.PpdsPlaningScenarios}>
        {translate('menu.planingScenarios')}
      </NavLink>
    </>
  )
}
