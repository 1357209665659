import { useEffect, useState } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import {
  isDemand,
  isDeploy,
  isJume,
  isOpti,
  isPpds,
  isPrgm,
  streamDemand,
  streamDeploy,
  streamJume,
  streamOpti,
  streamPpds,
  streamPrgm,
} from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { useProfile } from 'modules/user/queries'
import { demandRoutes, deployRoutes, jumeRoutes, optiRoutes, ppdsRoutes, prgmRoutes, Routes } from 'routes/routes'

export const useNavigateStream = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { profile } = useProfile()
  const currentStream = useCurrentStream((state) => state.currentStream)
  const setCurrentStream = useCurrentStream((state) => state.set)
  const [readyStream, setReadyStream] = useState(false)

  const jume = profile?.streams.find(({ title }) => title === streamJume)
  const opti = profile?.streams.find(({ title }) => title === streamOpti)
  const demand = profile?.streams.find(({ title }) => title === streamDemand)
  const prgm = profile?.streams.find(({ title }) => title === streamPrgm)
  const deploy = profile?.streams.find(({ title }) => title === streamDeploy)
  const ppds = profile?.streams.find(({ title }) => title === streamPpds)

  useEffect(() => {
    if (!profile?.streams) {
      return
    }

    if (!readyStream) {
      if (jumeRoutes.find((path) => matchPath({ path }, pathname)) && jume) {
        setCurrentStream(jume)
        setReadyStream(true)
        return
      }
      if (optiRoutes.find((path) => matchPath({ path }, pathname)) && opti) {
        setCurrentStream(opti)
        setReadyStream(true)
        return
      }
      if (demandRoutes.find((path) => matchPath({ path }, pathname)) && demand) {
        setCurrentStream(demand)
        setReadyStream(true)
        return
      }
      if (prgmRoutes.find((path) => matchPath({ path }, pathname)) && prgm) {
        setCurrentStream(prgm)
        setReadyStream(true)
        return
      }
      if (deployRoutes.find((path) => matchPath({ path }, pathname)) && deploy) {
        setCurrentStream(deploy)
        setReadyStream(true)
        return
      }
      if (ppdsRoutes.find((path) => matchPath({ path }, pathname)) && ppds) {
        setCurrentStream(ppds)
        setReadyStream(true)
        return
      }
    }

    if (!jumeRoutes.find((path) => matchPath({ path }, pathname)) && isJume(currentStream)) {
      setReadyStream(true)
      return navigate(Routes.Main)
    }
    if (!optiRoutes.find((path) => matchPath({ path }, pathname)) && isOpti(currentStream)) {
      setReadyStream(true)
      return navigate(Routes.PromoOptimizer)
    }
    if (!demandRoutes.find((path) => matchPath({ path }, pathname)) && isDemand(currentStream)) {
      setReadyStream(true)
      return navigate(Routes.Selections)
    }
    if (!prgmRoutes.find((path) => matchPath({ path }, pathname)) && isPrgm(currentStream)) {
      setReadyStream(true)
      return navigate(Routes.PrgmPulse)
    }
    if (!deployRoutes.find((path) => matchPath({ path }, pathname)) && isDeploy(currentStream)) {
      setReadyStream(true)
      return navigate(Routes.DeploySessionManagement)
    }
    if (!ppdsRoutes.find((path) => matchPath({ path }, pathname)) && isPpds(currentStream)) {
      setReadyStream(true)
      return navigate(Routes.PpdsMaterials)
    }
  }, [profile?.streams, currentStream])
}
