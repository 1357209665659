import { useEditComment } from 'prgm/comments/store/useEditComment'
import { useCustomerTable } from 'prgm/customersTable/store'
import { usePnlTable } from 'prgm/plTable/store'
import { useSearchFilters, useSearchTable } from 'prgm/searchPromo/store'
import { useSkuTable } from 'prgm/skuTable/store'

export const useClearAppCashPrgm = () => {
  const searchTableClear = useSearchTable((state) => state.clear)
  const searchFiltersClear = useSearchFilters((state) => state.clear)
  const clearPnlTable = usePnlTable((state) => state.clearPnlTable)
  const clearCustomerTable = useCustomerTable((state) => state.clear)
  const clearEditComment = useEditComment((state) => state.clear)
  const clearSkuSplit = useSkuTable((state) => state.clear)

  const clearAppCashPrgm = () => {
    searchTableClear()
    useSearchTable.persist.clearStorage()
    searchFiltersClear()
    clearPnlTable()
    clearCustomerTable()
    clearEditComment()
    clearSkuSplit()
  }

  return {
    clearAppCashPrgm,
  }
}
