import { devtools } from 'config/devtools'
import { generateErrorsState } from 'modules/filters/common/helpers'
import { create } from 'zustand'

import { StepError, UseStepsErrors } from './types'

export const useStepsStateErrors = create<UseStepsErrors>()(
  devtools((set, get) => generateErrorsState<StepError>(set, get), {
    store: 'stepsErrors',
  }),
)
