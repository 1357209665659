import { FC, useState } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg'
import cx from 'clsx'
import { NavLink } from 'components/template/NavLink'
import { NavTitle } from 'components/template/NavTitle'
import { useTranslate } from 'config/i18n'
import { isDeploy, isPrgm } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { Routes } from 'routes/routes'
import { SlideToggle, SlideToggleTypes } from 'ui/SlideToggle'

import classes from './NavMenuJobs.module.scss'

export const NavMenuJobs: FC = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const translate = useTranslate()

  const currentStream = useCurrentStream((state) => state.currentStream)

  const toggleMenuOpen = () => setIsSubMenuOpen((prev) => !prev)

  return (
    <>
      <NavTitle
        className={cx('menuItem', classes.disabled)}
        icon={<ArrowIcon />}
        isActive={isSubMenuOpen}
        onClick={toggleMenuOpen}
      >
        {translate('menu.jobs')}
      </NavTitle>
      <SlideToggle classNameWrap={classes.subMenu} open={isSubMenuOpen} type={SlideToggleTypes.NoTransform}>
        <NavLink
          className="menuItem"
          path={isPrgm(currentStream) ? Routes.PrgmJobs : isDeploy(currentStream) ? Routes.DeployJobs : Routes.Jobs}
        >
          {translate('menu.schedule')}
        </NavLink>
        <NavLink
          className="menuItem"
          path={
            isPrgm(currentStream)
              ? Routes.PrgmJobsStatus
              : isDeploy(currentStream)
              ? Routes.DeployJobsStatus
              : Routes.JobsStatus
          }
        >
          {translate('menu.status')}
        </NavLink>
      </SlideToggle>
    </>
  )
}
