const env = typeof process !== 'undefined' ? process.env : import.meta.env

export const IS_LOWCODE_ENABLED = env.VITE_IS_LOWCODE_ENABLED === 'true'
export const IS_WORKFLOW_ENABLED = env.VITE_IS_WORKFLOW_ENABLED === 'true'
export const IS_DEMO_OPTIMIZE_ENABLED = env.VITE_IS_DEMO_OPTIMIZE_ENABLED === 'true'
export const SESSION_OVER_MS = Number(localStorage.getItem('SESSION_OVER_MS') || env.VITE_SESSION_OVER_MS)
export const IS_PRGM_ENV = env.VITE_APP_NAME === 'Prgm'
export const IS_DANON = env.VITE_IS_DANON === 'true'
export const IS_JUME = env.VITE_IS_JUME === 'true'
export const IS_NEW_YEAR = env.VITE_IS_NEW_YEAR === 'true'
export const IS_SSO_ENABLED = env.VITE_ENABLE_SSO === 'true'

const global: any = typeof window !== 'undefined' ? window : {}
global.setSessionOver = (seconds: number) => {
  localStorage.setItem('SESSION_OVER_MS', String(seconds * 1000))
  window.location.reload()
}
global.removeSessionOver = () => {
  localStorage.removeItem('SESSION_OVER_MS')
  window.location.reload()
}

export const {
  VITE_API_URL: API_URL,
  VITE_WS_URL: WS_URL,
  TEST: isTest,
  VITE_APP_NAME: APP_NAME,
  VITE_LOGO: LOGO,
} = env
