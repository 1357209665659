import { FC, lazy as lazyImport } from 'react'
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom'

import { AuthLayout } from 'layouts/AuthLayout'
import { MainLayout } from 'layouts/MainLayout'
import { Loader, LoaderTypes } from 'ui/Loader'

import { ssoLoader } from './loaders/ssoLoader'
import { twoFactorLoader } from './loaders/twoFactorLoader'
import { Routes } from './routes'

const lazy = (importFn: () => Promise<any>) =>
  lazyImport(() =>
    importFn().catch((error) => {
      if (!window.location.href.includes('error_import_module')) {
        const currentURL = window.location.href
        const separator = currentURL.includes('?') ? '&' : '?'
        window.location.href = currentURL + separator + 'error_import_module=1'
      }
      throw error
    }),
  )

const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const LoginPage = lazy(() => import('pages/LoginPage'))
const TwoFactorExpiredPage = lazy(() => import('pages/TwoFactorExpiredPage'))
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'))
const PulsePage = lazy(() => import('pages/PulsePage'))
const DataSourcesPage = lazy(() => import('pages/DataSourcesPage'))
const ForecastPage = lazy(() => import('pages/ForecastPage'))
const ApprovalsPage = lazy(() => import('pages/ApprovalsPage'))
const MasterDataPage = lazy(() => import('pages/MasterDataPage'))
const MasterDataHierarchyPage = lazy(() => import('pages/MasterDataHierarchyPage'))
const StatusPage = lazy(() => import('pages/StatusPage'))
const InnovationManagementPage = lazy(() => import('pages/InnovationManagementPage'))
const PromoOptimizerPage = lazy(() => import('pages/PromoOptimizerPage'))
const CreatePromoScenarioPage = lazy(() => import('pages/CreatePromoScenarioPage'))
const PromoScenarioPage = lazy(() => import('pages/PromoScenarioPage'))
const PromoScenarioNotFoundPage = lazy(() => import('pages/PromoScenarioNotFoundPage'))
const ReplenScenariosPage = lazy(() => import('pages/ReplenScenariosPage'))
const CreateReplenScenarioPage = lazy(() => import('pages/CreateReplenScenarioPage'))
const ReplenScenarioPage = lazy(() => import('pages/ReplenScenarioPage'))
const ReplenWarehousesPage = lazy(() => import('pages/ReplenWarehousesPage'))
const ReplenOptimizerPage = lazy(() => import('pages/ReplenOptimizerPage'))
const ReplenOverviewPage = lazy(() => import('pages/ReplenOverviewPage'))
const OptimizerDemoPage = lazy(() => import('pages/OptimizerDemoPage'))
const CreateRtmScenarioPage = lazy(() => import('pages/CreateRtmScenarioPage'))
const RtmScenariosPage = lazy(() => import('pages/RtmScenariosPage'))
const RtmScenarioPage = lazy(() => import('pages/RtmScenarioPage'))
const RtmScenarioNotFoundPage = lazy(() => import('pages/RtmScenarioNotFoundPage'))
const CreateTetrisScenarioPage = lazy(() => import('pages/CreateTetrisScenarioPage'))
const TetrisScenariosPage = lazy(() => import('pages/TetrisScenariosPage'))
const TetrisScenarioPage = lazy(() => import('pages/TetrisScenarioPage'))
const TetrisScenarioNotFoundPage = lazy(() => import('pages/TetrisScenarioNotFoundPage'))
const CreateCfrScenarioPage = lazy(() => import('pages/CreateCfrScenarioPage'))
const CfrScenariosPage = lazy(() => import('pages/CfrScenariosPage'))
const CfrScenarioPage = lazy(() => import('pages/CfrScenarioPage'))
const CfrScenarioNotFoundPage = lazy(() => import('pages/CfrScenarioNotFoundPage'))

const PrgmCreatePage = lazy(() => import('pages/PrgmCreate'))
const PrgmSearchPage = lazy(() => import('pages/PrgmSearch'))
const PrgmDataHubPage = lazy(() => import('pages/PrgmDataHubPage'))
const PrgmPulsePage = lazy(() => import('pages/PrgmPulse'))
const PrgmApprovePage = lazy(() => import('pages/PrgmApprovePage'))
const PrgmReportsPage = lazy(() => import('pages/PrgmReportsPage'))

const SelectionsPage = lazy(() => import('pages/SelectionsPage'))
const SelectionPage = lazy(() => import('pages/SelectionPage'))
const SelectionNewPage = lazy(() => import('pages/SelectionNewPage'))
const DemandForecastPage = lazy(() => import('pages/DemandForecastPage'))
const MasterDataUploadPage = lazy(() => import('../pages/MasterDataUploadPage'))
const ForecastManualUploadPage = lazy(() => import('../pages/ForecastManualUploadPage'))
const JobsPage = lazy(() => import('pages/JobsPage'))
const JobPage = lazy(() => import('pages/JobPage'))
const JobNewPage = lazy(() => import('pages/JobNewPage'))
const JobsStatusPage = lazy(() => import('pages/JobsStatusPage'))
const JobDetailPage = lazy(() => import('pages/JobDetailPage'))
const DeployScenariosPage = lazy(() => import('pages/DeployScenariosPage'))
const DeployScenarioDataManagePage = lazy(() => import('pages/DeployScenarioDataManagePage'))
const DeployScenarioSettingsPage = lazy(() => import('pages/DeployScenarioSettingsPage'))
const DeploySessionPage = lazy(() => import('pages/DeploySessionPage'))
const DeployDataHubTablePage = lazy(() => import('pages/DeployDataHubTablePage'))
const StockAllocationPage = lazy(() => import('pages/StockAllocationPage'))
const DataHandlingPage = lazy(() => import('pages/DataHandlingPage'))
const TransportOptimizationPage = lazy(() => import('pages/TransportOptimizationPage'))
const PpdsMaterialsPage = lazy(() => import('pages/PpdsMaterialsPage'))
const PpdsMaterialsFamilyPage = lazy(() => import('pages/PpdsMaterialsFamilyPage'))
const PpdsResourceFamiliesPage = lazy(() => import('pages/PpdsResourceFamiliesPage'))

const routes: RouteObject[] = [
  {
    path: Routes.Login,
    element: <AuthLayout />,
    errorElement: <Loader type={LoaderTypes.SpinnerCenter} />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: Routes.ResetPassword,
    element: <AuthLayout />,
    errorElement: <Loader type={LoaderTypes.SpinnerCenter} />,
    loader: () => {
      twoFactorLoader()
      return null
    },
    children: [
      {
        index: true,
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    path: Routes.TwoFactorExpired,
    element: <AuthLayout />,
    errorElement: <Loader type={LoaderTypes.SpinnerCenter} />,
    children: [
      {
        index: true,
        element: <TwoFactorExpiredPage />,
      },
    ],
  },
  {
    path: Routes.Main,
    element: <MainLayout />,
    errorElement: <MainLayout loading />,
    loader: () => {
      twoFactorLoader()
      ssoLoader()
      return null
    },
    children: [
      {
        index: true,
        element: <PulsePage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: Routes.DataSources,
        element: <DataSourcesPage />,
      },
      {
        path: Routes.Forecast,
        element: <ForecastPage />,
      },
      {
        path: Routes.Approvals,
        element: <ApprovalsPage />,
      },
      {
        path: Routes.Status,
        element: <StatusPage />,
      },
      {
        path: Routes.InnovationManagement,
        element: <InnovationManagementPage />,
      },
      {
        path: Routes.MasterData,
        element: <MasterDataPage />,
      },
      {
        path: Routes.MasterDataHierarchy,
        element: <MasterDataHierarchyPage />,
      },
      {
        path: Routes.PromoOptimizer,
        element: <PromoOptimizerPage />,
      },
      {
        path: Routes.CreatePromoScenario,
        element: <CreatePromoScenarioPage />,
      },
      {
        path: Routes.PromoScenario,
        element: <PromoScenarioPage />,
      },
      {
        path: Routes.PromoScenarioNotFound,
        element: <PromoScenarioNotFoundPage />,
      },
      {
        path: Routes.ReplenScenarios,
        element: <ReplenScenariosPage />,
      },
      {
        path: Routes.CreateReplenScenario,
        element: <CreateReplenScenarioPage />,
      },
      {
        path: Routes.ReplenScenario,
        element: <ReplenScenarioPage />,
      },
      {
        path: Routes.ReplenScenarioNotFound,
        element: <div>ReplenScenarioNotFound</div>,
      },
      {
        path: Routes.ReplenWarehouses,
        element: <ReplenWarehousesPage />,
      },
      {
        path: Routes.ReplenOptimizer,
        element: <ReplenOptimizerPage />,
      },
      {
        path: Routes.ReplenOverview,
        element: <ReplenOverviewPage />,
      },
      {
        path: Routes.OptimizerDemo,
        element: <OptimizerDemoPage />,
      },
      {
        path: Routes.CreateRtmScenario,
        element: <CreateRtmScenarioPage />,
      },
      {
        path: Routes.RtmOptimizer,
        element: <RtmScenariosPage />,
      },
      {
        path: Routes.RtmScenario,
        element: <RtmScenarioPage />,
      },
      {
        path: Routes.RtmScenarioNotFound,
        element: <RtmScenarioNotFoundPage />,
      },
      {
        path: Routes.CreateTetrisScenario,
        element: <CreateTetrisScenarioPage />,
      },
      {
        path: Routes.TetrisOptimizer,
        element: <TetrisScenariosPage />,
      },
      {
        path: Routes.TetrisScenario,
        element: <TetrisScenarioPage />,
      },
      {
        path: Routes.TetrisScenarioNotFound,
        element: <TetrisScenarioNotFoundPage />,
      },
      {
        path: Routes.CreateCfrScenario,
        element: <CreateCfrScenarioPage />,
      },
      {
        path: Routes.CfrOptimizer,
        element: <CfrScenariosPage />,
      },
      {
        path: Routes.CfrScenario,
        element: <CfrScenarioPage />,
      },
      {
        path: Routes.CfrScenarioNotFound,
        element: <CfrScenarioNotFoundPage />,
      },
      {
        path: Routes.Selections,
        element: <SelectionsPage />,
      },
      {
        path: Routes.Selection,
        element: <SelectionPage />,
      },
      {
        path: Routes.NewSelection,
        element: <SelectionNewPage />,
      },
      {
        path: Routes.DemandForecast,
        element: <DemandForecastPage />,
      },
      {
        path: Routes.PrgmPulse,
        element: <PrgmPulsePage />,
      },
      {
        path: Routes.PrgmCreate,
        element: <PrgmCreatePage />,
        children: [
          {
            element: <PrgmCreatePage />,
            path: ':promoId',
          },
        ],
      },
      {
        path: Routes.PrgmSearch,
        element: <PrgmSearchPage />,
      },
      {
        path: Routes.PrgmDataHub,
        element: <PrgmDataHubPage />,
      },
      {
        path: Routes.PrgmReports,
        element: <PrgmReportsPage />,
      },
      {
        path: Routes.PrgmApprove,
        element: <PrgmApprovePage />,
      },
      {
        path: Routes.MasterDataSourceLoading,
        element: <MasterDataUploadPage />,
      },
      {
        path: Routes.ForecastDataSourceLoading,
        element: <ForecastManualUploadPage />,
      },
      {
        path: Routes.Jobs,
        element: <JobsPage />,
      },
      {
        path: Routes.JobsStatus,
        element: <JobsStatusPage />,
      },
      {
        path: Routes.JobDetailPage,
        element: <JobDetailPage />,
      },
      {
        path: Routes.Job,
        element: <JobPage />,
      },
      {
        path: Routes.NewJob,
        element: <JobNewPage />,
      },
      {
        path: Routes.DeployJobs,
        element: <JobsPage />,
      },
      {
        path: Routes.DeployJobsStatus,
        element: <JobsStatusPage />,
      },
      {
        path: Routes.DeployJobDetailPage,
        element: <JobDetailPage />,
      },
      {
        path: Routes.DeployJob,
        element: <JobPage />,
      },
      {
        path: Routes.DeployNewJob,
        element: <JobNewPage />,
      },
      {
        path: Routes.PrgmJobs,
        element: <JobsPage />,
      },
      {
        path: Routes.PrgmJobsStatus,
        element: <JobsStatusPage />,
      },
      {
        path: Routes.PrgmJobDetailPage,
        element: <JobDetailPage />,
      },
      {
        path: Routes.PrgmJob,
        element: <JobPage />,
      },
      {
        path: Routes.PrgmNewJob,
        element: <JobNewPage />,
      },
      {
        path: Routes.DeployScenarioHistory,
        element: <DeployScenariosPage />,
      },
      {
        path: Routes.DeployScenarioSettings,
        element: <DeployScenarioSettingsPage />,
      },
      {
        path: Routes.DeployCreateScenario,
        element: <DeployScenarioSettingsPage isCreate />,
      },
      {
        path: Routes.DeployScenarioSettingsEmpty,
        element: <DeployScenarioSettingsPage />,
      },
      {
        path: Routes.DeploySessionManagement,
        element: <DeploySessionPage />,
      },
      {
        path: Routes.DeployScenarioManagement,
        element: <DeployScenarioDataManagePage />,
      },
      {
        path: Routes.DataHandling,
        element: <DataHandlingPage />,
      },
      {
        path: Routes.DeployDataHubTable,
        element: <DeployDataHubTablePage />,
      },
      {
        path: Routes.DeployStockAllocation,
        element: <StockAllocationPage />,
      },
      {
        path: Routes.DeployTransportOptimization,
        element: <TransportOptimizationPage />,
      },
      {
        path: Routes.PpdsMaterials,
        element: <PpdsMaterialsPage />,
      },
      {
        path: Routes.PpdsMaterialsFamily,
        element: <PpdsMaterialsFamilyPage />,
      },
      {
        path: Routes.PpdsResourceFamilies,
        element: <PpdsResourceFamiliesPage />,
      },
    ],
  },
]

export const RenderRoute: FC = () => (
  <RouterProvider fallbackElement={<Loader type={LoaderTypes.SpinnerCenter} />} router={createBrowserRouter(routes)} />
)
