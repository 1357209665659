import { devtools } from 'config/devtools'
import { SkuTableRow } from 'prgm/skuTable/interfaces'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseSkuTable {
  rows: SkuTableRow[]
  open: boolean
  setOpen: (open: boolean) => void
  setSkuTableRows: (rows: SkuTableRow[]) => void
  addRows: (ids: number[]) => void
  removeRow: (id: number) => void
  clear: () => void
}

export const useSkuTable = create<UseSkuTable>()(
  devtools(
    persist(
      (set) => ({
        rows: [],
        open: true,
        setOpen: (open) => set({ open }),
        setSkuTableRows: (rows) => {
          set(() => {
            const rowsWithIds = rows.map((row, index) => ({
              ...row,
              id: index,
            }))

            return {
              rows: rowsWithIds,
            }
          })
        },
        addRows: (ids) =>
          set(({ rows }) => {
            const updatedRows = rows.map((row) => ({
              ...row,
              isSelected: row.isSelected || ids.includes(row.id),
            }))
            return { rows: updatedRows }
          }),
        removeRow: (id) =>
          set(({ rows }) => {
            const updatedRows = rows.map((row) => ({
              ...row,
              isSelected: row.isSelected && id !== row.id,
            }))
            return { rows: updatedRows }
          }),
        clear: () => set({ rows: [], open: true }),
      }),
      {
        name: 'prgmSkuTable',
      },
    ),
  ),
)
