export const updateDataStatusMs = 5000 // (ms) Должно быть больше 1000
export const updateScenarioMs = 5000 // (ms) Должно быть больше 1000

export const inputMasks = {
  date: {
    mask: '99.99.9999',
    placeholder: '__.__.____',
  },
  dateRange: {
    mask: '99.99.9999 — 99.99.9999',
    placeholder: '__.__.____ — __.__.____',
  },
}

export const updateSystemMinutes = 15
