import { FC, PropsWithChildren, ReactNode } from 'react'
import { NavLink as NavLinkNative } from 'react-router-dom'

import cx from 'clsx'

import classes from './NavLink.module.scss'

interface NavLinkProps {
  path: string
  className?: string
  icon?: ReactNode
  end?: boolean
}

export const NavLink: FC<PropsWithChildren<NavLinkProps>> = ({ path, icon, className, children, end }) => {
  if (!children && !icon) {
    return null
  }

  return (
    <NavLinkNative
      className={({ isActive }) => cx(classes.wrap, className, { [classes.active]: isActive })}
      end={end}
      to={path}
    >
      {!!icon && <div className={classes.icon}>{icon}</div>}
      <span>{children}</span>
    </NavLinkNative>
  )
}
