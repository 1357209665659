import { FC, useState } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg'
import cx from 'clsx'
import { NavLink } from 'components/template/NavLink'
import { NavTitle } from 'components/template/NavTitle'
import { useTranslate } from 'config/i18n'
import { NavMenuJobs } from 'modules/jobs/components/NavMenuJobs'
import { useJobsPermissionsByStream } from 'modules/jobs/handlers'
import { Routes } from 'routes/routes'
import { SlideToggle, SlideToggleTypes } from 'ui/SlideToggle'

import classes from './NavMenuDemand.module.scss'

export const NavMenuDemand: FC = () => {
  const [isLoadDataOpen, setIsLoadDataOpen] = useState<boolean>(false)
  const translate = useTranslate()
  const { canReadJobsDb, canEditJobsDb } = useJobsPermissionsByStream()

  const toggleMenuOpen = () => setIsLoadDataOpen((prevState) => !prevState)

  return (
    <>
      <NavLink className="menuItem" path={Routes.Selections}>
        {translate('menu.selections')}
      </NavLink>
      <NavLink className="menuItem" path={Routes.ForecastDataSourceLoading}>
        {translate('menu.forecastLoad')}
      </NavLink>

      <NavTitle
        className={cx('menuItem', classes.disabled)}
        icon={<ArrowIcon />}
        isActive={isLoadDataOpen}
        onClick={toggleMenuOpen}
      >
        {translate('menu.masterDataDB')}
      </NavTitle>

      <SlideToggle classNameWrap={classes.subMenu} open={isLoadDataOpen} type={SlideToggleTypes.NoTransform}>
        <NavLink className="menuItem" path={Routes.MasterDataSourceLoading}>
          {translate('menu.sourceLoading')}
        </NavLink>
        <NavLink className="menuItem" path={Routes.DataHandling}>
          {translate('menu.dataHandling')}
        </NavLink>
      </SlideToggle>
      {(canReadJobsDb || canEditJobsDb) && <NavMenuJobs />}
    </>
  )
}
