import { RefObject, useCallback, useEffect } from 'react'

import { useResizeObserver } from 'hooks/useResizeObserver'

export const useInfiniteScroll = (
  cb: (() => void) | undefined,
  refScroll: RefObject<HTMLDivElement>,
  refContent: RefObject<HTMLDivElement>,
  options?: {
    enabled?: boolean
    threshold?: number
  },
) => {
  const threshold = options?.threshold || 150
  const enabled = options?.enabled ?? true

  const loadMore = useCallback(() => {
    if (
      enabled &&
      refScroll.current &&
      refContent.current &&
      refScroll.current.offsetHeight + refScroll.current.scrollTop + threshold > refContent.current.clientHeight
    ) {
      cb?.()
    }
  }, [])

  useResizeObserver(refContent, loadMore)

  useEffect(() => {
    loadMore()
    if (refScroll.current) {
      refScroll.current.addEventListener('scroll', loadMore)
    }
    return () => refScroll.current?.removeEventListener('scroll', loadMore)
  }, [options?.enabled, refScroll.current, refContent.current])
}
