import { RefObject, useEffect } from 'react'

import { isEqual } from 'lodash'

export const useSizeEqual = (
  ref1: RefObject<HTMLElement>,
  ref2: RefObject<HTMLElement>,
  cb: (equal: boolean) => void,
  deps?: any[],
) => {
  useEffect(() => {
    if (!ref1?.current || !ref2.current) {
      return
    }

    const sizes: Record<number, { clientWidth: number; clientHeight: number }> = {}

    const onChange = () => {
      cb(
        isEqual(sizes[0], sizes[1]) &&
          ref1.current?.getBoundingClientRect().width === ref2.current?.getBoundingClientRect().width,
      )
    }

    const resizeObserver1 = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { clientWidth, clientHeight } = entry.target
        if (!isEqual({ clientWidth, clientHeight }, sizes[0])) {
          sizes[0] = { clientWidth, clientHeight }
          queueMicrotask(onChange)
        }
      })
    })
    const resizeObserver2 = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { clientWidth, clientHeight } = entry.target
        if (!isEqual({ clientWidth, clientHeight }, sizes[1])) {
          sizes[1] = { clientWidth, clientHeight }
          queueMicrotask(onChange)
        }
      })
    })

    resizeObserver1.observe(ref1.current)
    resizeObserver2.observe(ref2.current)

    return () => {
      resizeObserver1.disconnect()
      resizeObserver2.disconnect()
    }
  }, [ref1.current, ref2.current, ...(deps || [])])
}
