import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/auth'

interface Endpoints {
  login: NoParamEndpointConstructor
  refresh: NoParamEndpointConstructor
  resetPassword: NoParamEndpointConstructor
  logoutInform: NoParamEndpointConstructor
  resendTwoFactorCode: NoParamEndpointConstructor
  twoFactorLogin: NoParamEndpointConstructor
  checkTwoFactorCode: NoParamEndpointConstructor
  getAuthSsoTokens: NoParamEndpointConstructor
}

export const authEndpoints: Endpoints = {
  login: () => `${baseUrl}/login`,
  refresh: () => `${baseUrl}/refresh`,
  resetPassword: () => '/account/reset-password',
  logoutInform: () => '/account/termination-session',
  resendTwoFactorCode: () => `${baseUrl}/resending-two-factor-authentication-code`,
  twoFactorLogin: () => `${baseUrl}/two-factor-authentication`,
  checkTwoFactorCode: () => `${baseUrl}/check-two-factor-authentication-code`,
  getAuthSsoTokens: () => `${baseUrl}/sso-callback`,
}
