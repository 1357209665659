import { devtools } from 'config/devtools'
import { merge } from 'lodash'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseSearchTable {
  columns: Record<string, { size?: number }>
  onResize: (prop: string | number, size: number) => void
  clear: () => void
}

export const useSearchTable = create<UseSearchTable>()(
  devtools(
    persist(
      (set) => ({
        columns: {},
        onResize: (prop, size) => {
          set(({ columns }) => {
            if (!columns[prop]) {
              columns[prop] = {}
            }
            columns[prop].size = size
            return { columns }
          })
        },
        clear: () => set({ columns: {} }),
      }),
      {
        name: 'prgmSearchTable',
        merge: (persistedState, currentState) => merge(currentState, persistedState),
      },
    ),
    {
      name: 'prgmSearchTable',
    },
  ),
)
