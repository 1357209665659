import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { IS_JUME } from 'constants/env'
import { useLastSession } from 'modules/session/store'
import { Routes } from 'routes/routes'

export const useNavigateToLastPage = () => {
  const lastPage = useLastSession((state) => state.page)
  const location = useLocation()
  const navigate = useNavigate()
  const setIsNavigated = useLastSession((state) => state.setIsNavigated)

  const navigateToLastPage = useCallback(() => {
    if (lastPage && (location.pathname === Routes.Main || location.pathname === Routes.Login) && IS_JUME) {
      navigate(lastPage)
    } else {
      navigate(Routes.Main)
    }
    setTimeout(() => setIsNavigated(true), 300)
  }, [location, lastPage])

  return { navigateToLastPage }
}
