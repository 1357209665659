export enum Routes {
  Main = '/',
  Login = '/login',
  ResetPassword = '/reset-password',
  AuthSso = '/api/auth/sso-login',
  DataSources = '/data',
  MasterData = '/master-data',
  MasterDataHierarchy = '/master-data/:hierarchyId',
  Status = '/status',
  InnovationManagement = '/innovation-management',
  Forecast = '/project/:projectId/forecast',
  ReplenOptimizer = '/replen-optimizer',
  Approvals = '/approvals',
  PromoOptimizer = '/promo-optimizer',
  CreatePromoScenario = '/create-promo-scenario',
  CreateReplenScenario = '/create-replen-scenario',
  PromoScenario = '/promo-optimizer/promo-scenario/:scenarioId',
  PromoScenarioNotFound = '/promo-optimizer/promo-scenario/not-found',
  ReplenScenario = '/replen-optimizer/replen-scenarios/:scenarioId',
  ReplenScenarioNotFound = '/replen-optimizer/replen-scenarios/not-found',
  ReplenWarehouses = '/replen-optimizer/replen-warehouses',
  ReplenScenarios = '/replen-optimizer/replen-scenarios',
  ReplenOverview = '/replen-optimizer/replen-overview',
  OptimizerDemo = '/promo-optimizer-demo',
  CreateRtmScenario = '/create-rtm-scenario',
  RtmScenario = '/rtm-optimizer/rtm-scenario/:scenarioId',
  RtmOptimizer = '/rtm-optimizer',
  RtmScenarioNotFound = '/rtm-optimizer/rtm-scenario/not-found',
  CreateTetrisScenario = '/create-tetris-scenario',
  TetrisScenario = '/tetris-optimizer/tetris-scenario/:scenarioId',
  TetrisOptimizer = '/tetris-optimizer',
  TetrisScenarioNotFound = '/tetris-optimizer/tetris-scenario/not-found',
  CreateCfrScenario = '/create-cfr-scenario',
  CfrScenario = '/cfr-optimizer/cfr-scenario/:scenarioId',
  CfrOptimizer = '/cfr-optimizer',
  CfrScenarioNotFound = '/cfr-optimizer/cfr-scenario/not-found',
  TwoFactorExpired = '/two-factor-expired',
  PrgmCreate = '/prgm/create',
  PrgmEditPromo = '/prgm/create/:promoId',
  PrgmSearch = '/prgm/search',
  PrgmPulse = '/prgm',
  PrgmApprove = '/prgm/approve',
  PrgmDataHub = '/prgm/data-hub',
  PrgmReports = '/prgm/reports',
  Jobs = '/jobs',
  JobsStatus = '/jobs-status',
  Job = '/jobs/:jobId',
  NewJob = '/jobs/new',
  JobDetailPage = '/jobs-detail/:jobId',
  PrgmJobs = '/prgm/jobs',
  PrgmJobsStatus = '/prgm/jobs-status',
  PrgmJob = '/prgm/jobs/:jobId',
  PrgmNewJob = '/prgm/jobs/new',
  PrgmJobDetailPage = '/prgm/jobs-detail/:jobId',
  Selections = '/selections',
  Selection = '/selections/:selectionId',
  NewSelection = '/selections/new',
  DemandForecast = '/selections/:selectionId/forecast',
  MasterDataSourceLoading = '/master-data-upload',
  ForecastDataSourceLoading = '/forecast-data-upload',
  DataHandling = '/data-handling',
  DeployJobs = '/deploy/jobs',
  DeployJobsStatus = '/deploy/jobs-status',
  DeployJob = '/deploy/jobs/:jobId',
  DeployNewJob = '/deploy/jobs/new',
  DeployJobDetailPage = '/deploy/jobs-detail/:jobId',
  DeploySessionManagement = '/deploy/data-hub',
  DeployDataHubTable = '/deploy/data-hub/:id',
  DeployScenarioManagement = '/deploy/scenarios-management',
  DeployScenarioHistory = '/deploy/scenarios-history',
  DeployScenarioSettings = '/deploy/scenario-settings/:scenarioId',
  DeployScenarioSettingsEmpty = '/deploy/scenario-settings',
  DeployCreateScenario = '/deploy/scenario-settings/create',
  DeployStockAllocation = '/deploy/stock-allocation',
  DeployTransportOptimization = '/deploy/transport-optimization',
  DeployReporting = '/deploy/reporting',
  DeployExecutive = '/deploy/executive',
  PpdsMaterials = '/ppds/materials',
  PpdsResources = '/ppds/resources',
  PpdsRecipes = '/ppds/recipes',
  PpdsMaterialsFamily = '/ppds/materials-family',
  PpdsResourceFamilies = '/ppds/resource-families',
  PpdsPlaningScenarios = '/ppds/planing-scenarios',
}

export const forecastRoutes = [
  Routes.Main,
  Routes.Forecast,
  Routes.Status,
  Routes.InnovationManagement,
  Routes.MasterData,
  Routes.DataSources,
  Routes.OptimizerDemo,
]

export const replenOptimizerRoutes = [
  Routes.ReplenOptimizer,
  Routes.CreateReplenScenario,
  Routes.ReplenScenario,
  Routes.ReplenScenarioNotFound,
  Routes.ReplenWarehouses,
  Routes.ReplenScenarios,
  Routes.ReplenOverview,
]

export const promoOptimizerRoutes = [
  Routes.PromoOptimizer,
  Routes.CreatePromoScenario,
  Routes.PromoScenario,
  Routes.PromoScenarioNotFound,
]

export const rtmOptimizerRoutes = [
  Routes.CreateRtmScenario,
  Routes.RtmScenario,
  Routes.RtmOptimizer,
  Routes.RtmScenarioNotFound,
]

export const tetrisOptimizerRoutes = [
  Routes.CreateTetrisScenario,
  Routes.TetrisScenario,
  Routes.TetrisOptimizer,
  Routes.TetrisScenarioNotFound,
]

export const cfrOptimizerRoutes = [
  Routes.CreateCfrScenario,
  Routes.CfrScenario,
  Routes.CfrOptimizer,
  Routes.CfrScenarioNotFound,
]

export const jobsRoutes = [Routes.Jobs, Routes.NewJob, Routes.JobsStatus, Routes.JobDetailPage, Routes.Job]

export const deployJobsRoutes = [
  Routes.DeployJobs,
  Routes.DeployNewJob,
  Routes.DeployJobsStatus,
  Routes.DeployJobDetailPage,
  Routes.DeployJob,
]

export const prgmJobsRoutes = [
  Routes.PrgmJobs,
  Routes.PrgmNewJob,
  Routes.PrgmJobsStatus,
  Routes.PrgmJobDetailPage,
  Routes.PrgmJob,
]

export const jumeRoutes = [...forecastRoutes, ...replenOptimizerRoutes, ...jobsRoutes]

export const optiRoutes = [
  ...promoOptimizerRoutes,
  ...rtmOptimizerRoutes,
  ...tetrisOptimizerRoutes,
  ...cfrOptimizerRoutes,
  ...jobsRoutes,
]

export const demandRoutes = [
  Routes.Selections,
  Routes.Selection,
  Routes.NewSelection,
  Routes.DemandForecast,
  Routes.MasterDataSourceLoading,
  Routes.ForecastDataSourceLoading,
  Routes.DataHandling,
  ...jobsRoutes,
]

export const prgmRoutes = [
  Routes.PrgmCreate,
  Routes.PrgmSearch,
  Routes.PrgmPulse,
  Routes.PrgmApprove,
  Routes.PrgmDataHub,
  Routes.PrgmEditPromo,
  Routes.PrgmReports,
  ...prgmJobsRoutes,
]

export const deployRoutes = [
  Routes.DeploySessionManagement,
  Routes.DeployDataHubTable,
  Routes.DeployScenarioManagement,
  Routes.DeployScenarioSettings,
  Routes.DeployScenarioHistory,
  Routes.DeployStockAllocation,
  Routes.DeployTransportOptimization,
  Routes.DeployScenarioSettings,
  Routes.DeployCreateScenario,
  Routes.DeployScenarioSettingsEmpty,
  ...deployJobsRoutes,
]

export const ppdsRoutes = [
  Routes.PpdsMaterials,
  Routes.PpdsResources,
  Routes.PpdsRecipes,
  Routes.PpdsResourceFamilies,
  Routes.PpdsMaterialsFamily,
  Routes.PpdsPlaningScenarios,
]
