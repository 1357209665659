import { forwardRef, ReactNode, useEffect, useState } from 'react'

import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import cx from 'clsx'
import { Loader, LoaderColors, LoaderTypes } from 'ui/Loader'

import classes from './Checkbox.module.scss'

export enum CheckboxSizes {
  Medium = 'medium',
  Small = 'small',
}

interface CheckboxProps {
  children?: ReactNode
  className?: string
  classNameCheckbox?: string
  classNameLabel?: string
  disabled?: boolean
  noEditable?: boolean
  value?: boolean
  onChange?: (value: boolean) => void
  size?: CheckboxSizes
  loading?: boolean
  isPartially?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      classNameCheckbox,
      classNameLabel,
      value,
      disabled,
      noEditable,
      onChange,
      children,
      size = CheckboxSizes.Medium,
      loading,
      isPartially,
    },
    ref,
  ) => {
    const [valueInternal, setValueInternal] = useState(!!value)
    const noLabel = !children && children !== 0

    const onChangeInternal = () => {
      if (value !== undefined) {
        onChange?.(!value)
      } else {
        setValueInternal((prev) => {
          window.requestAnimationFrame(() => onChange?.(!prev))
          return !prev
        })
      }
    }

    useEffect(() => {
      setValueInternal(!!value)
    }, [value])

    return (
      <div
        className={cx(classes.wrap, className, classes[size], {
          [classes.checked]: valueInternal,
          [classes.disabled]: disabled,
          [classes.noStretch]: noLabel,
          [classes.partially]: isPartially,
          [classes.noEditable]: noEditable,
        })}
        onClick={onChangeInternal}
      >
        <input className={classes.input} onChange={onChangeInternal} ref={ref} type="checkbox" />
        <div className={classes.checkboxCont}>
          {loading ? (
            <Loader color={LoaderColors.Gray} type={LoaderTypes.SpinnerMini} />
          ) : (
            <div className={cx(classes.checkbox, classNameCheckbox)}>
              <CheckIcon className={classes.icon} />
            </div>
          )}
        </div>
        {!noLabel && <div className={cx(classes.label, classNameLabel)}>{children}</div>}
      </div>
    )
  },
)
