import { FC, Suspense, useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-menu.svg'
import cx from 'clsx'
import { NavMenu } from 'components/template/NavMenu'
import { ProfileLinks } from 'components/template/ProfileLinks'
import { UnavailableBlock } from 'components/template/UnavailableBlock'
import { SESSION_OVER_MS } from 'constants/env'
import { projectTypeMap } from 'constants/projects'
import { compose } from 'hocs'
import { withAuth } from 'hocs/withAuth'
import { withUpdatingSystem } from 'hocs/withUpdatingSystem'
import { useOpenMenu } from 'hooks/useOpenMenu'
import { useProjectType } from 'hooks/useProjectType'
import { useUnsavedChangesStateConfirm } from 'hooks/useUnsavedChangesStateConfirm'
import { EditMode } from 'jume/editMode/components/EditMode'
import { ModalLayout } from 'layouts/ModalLayout'
import { ProjectSelect } from 'modules/projects/components/ProjectSelect'
import { useProject } from 'modules/projects/queries'
import { SessionOver } from 'modules/session/components/SessionOver'
import { StreamSelect } from 'modules/streams/components/StreamSelect'
import { isDemand, isPrgm, streamsWithProject } from 'modules/streams/constants/streams'
import { useNavigateStream, useSetLangByStream } from 'modules/streams/handlers'
import { useCurrentStream } from 'modules/streams/store'
import { useProfile } from 'modules/user/queries'
import { Routes } from 'routes/routes'
import { Loader, LoaderTypes } from 'ui/Loader'
import { Logo } from 'ui/Logo'

import classes from './MainLayout.module.scss'

interface MainLayoutProps {
  loading?: boolean
}

export const MainLayoutInternal: FC<MainLayoutProps> = ({ loading }) => {
  const [hoverMenu, setHoverMenu] = useState(false)
  const [animatedMenu, setAnimatedMenu] = useState(false)
  const [disabledEvents, setDisabledEvents] = useState(false)
  const openMenu = useOpenMenu((state) => state.open)
  const toggleMenu = useOpenMenu((state) => state.toggle)
  const { project } = useProject()
  const { handleReturnBack, isUnavailable } = useProjectType()
  const { profile } = useProfile()
  const currentStream = useCurrentStream((state) => state.currentStream)

  useUnsavedChangesStateConfirm()
  useNavigateStream()
  useSetLangByStream()

  useEffect(() => {
    setAnimatedMenu(!openMenu)
  }, [openMenu])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (hoverMenu) {
      setDisabledEvents(true)
      interval = setTimeout(() => {
        setDisabledEvents(false)
      }, 500)
    }
    return () => {
      clearTimeout(interval)
    }
  }, [hoverMenu])

  return (
    <ModalLayout>
      <section
        className={cx(classes.wrap, { [classes.open]: openMenu, [classes.animatedMenu]: animatedMenu && !openMenu })}
        data-container=""
      >
        {!!SESSION_OVER_MS && <SessionOver />}
        {openMenu && <ProfileLinks />}

        <aside
          className={cx(classes.sidebar, {
            [classes.hoverMenu]: hoverMenu,
            [classes.disabledEvents]: disabledEvents,
          })}
          onMouseEnter={() => setHoverMenu(!openMenu)}
          onMouseLeave={() => setHoverMenu(false)}
        >
          <div className={classes.openMenuCont}>
            <div className={classes.openMenu} onClick={toggleMenu}>
              <ArrowIcon className="svg-img" />
            </div>
          </div>
          <Link
            className={classes.logoLink}
            to={
              (project && (projectTypeMap[project?.type.code] as unknown as string)) ||
              (isPrgm(currentStream) && Routes.PrgmPulse) ||
              (isDemand(currentStream) && Routes.Selections) ||
              Routes.Main
            }
          >
            <Logo className={classes.logo} />
          </Link>

          {!!profile?.streams.length && profile.streams.length > 1 && <StreamSelect className={classes.select} />}
          {currentStream && streamsWithProject.includes(currentStream.title) && (
            <ProjectSelect className={classes.select} />
          )}

          <NavMenu className={classes.menu} />

          {!openMenu && <ProfileLinks extended />}
        </aside>
        <div
          className={classes.openSidebar}
          onMouseEnter={() => setHoverMenu(!openMenu)}
          onMouseLeave={() => setHoverMenu(false)}
        />

        <main className={classes.main}>
          <div className={classes.content}>
            <Suspense fallback={<Loader className={classes.loader} type={LoaderTypes.SpinnerCenter} />}>
              {loading ? (
                <Loader className={classes.loader} type={LoaderTypes.SpinnerCenter} />
              ) : isUnavailable ? (
                <UnavailableBlock onClick={handleReturnBack} />
              ) : (
                <div className={classes.inside}>
                  <Outlet />
                </div>
              )}
            </Suspense>
          </div>
          <EditMode />
        </main>
      </section>
    </ModalLayout>
  )
}

export const MainLayout = compose(MainLayoutInternal, withAuth, withUpdatingSystem)
