import { FC } from 'react'

import { NavLink } from 'components/template/NavLink'
import { useTranslate } from 'config/i18n'
import { ProjectTypes } from 'constants/projects'
import { useProject } from 'modules/projects/queries'
import { Routes } from 'routes/routes'

export const NavMenuOpti: FC = () => {
  const { project } = useProject()
  const translate = useTranslate()

  return (
    <>
      {project?.type.code === ProjectTypes.PromoOptimizer && (
        <NavLink path={Routes.PromoOptimizer}>{translate('menu.allScenarios')}</NavLink>
      )}
      {project?.type.code === ProjectTypes.RtmOptimizer && (
        <NavLink path={Routes.RtmOptimizer}>{translate('menu.allScenarios')}</NavLink>
      )}
      {project?.type.code === ProjectTypes.TetrisOptimizer && (
        <NavLink path={Routes.TetrisOptimizer}>{translate('menu.allScenarios')}</NavLink>
      )}
      {project?.type.code === ProjectTypes.CfrOptimizer && (
        <NavLink path={Routes.CfrOptimizer}>{translate('menu.allScenarios')}</NavLink>
      )}
    </>
  )
}
