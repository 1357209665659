import { useCallback, useRef } from 'react'

import {
  DefaultError,
  FetchNextPageOptions,
  InfiniteData,
  QueryClient,
  QueryKey,
  useInfiniteQuery as useInfiniteQueryNative,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query'
import { GetAllData } from 'interfaces/api.interfaces'
import { flatten, last } from 'lodash'
import { useQueryClient } from 'packages/react-query/src/hooks/useQueryClient'

export const useInfiniteQuery = <
  TQueryFnData,
  TError = DefaultError,
  TData extends InfiniteData<TQueryFnData> = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<
    UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey, number>,
    'getNextPageParam' | 'initialPageParam'
  >,
  queryClient?: QueryClient,
): UseInfiniteQueryResult<TData, TError> => {
  const commonQueryClient = useQueryClient()
  const refOptions = useRef(options)
  refOptions.current = options

  const result = useInfiniteQueryNative<TQueryFnData, TError, TData, TQueryKey, number>(
    {
      getNextPageParam: (lastPage) => (lastPage as GetAllData<any>)?.next || undefined,
      initialPageParam: 1,
      ...options,
    },
    queryClient,
  )

  const fetchNextPage = useCallback(
    ((fetchNextPageOptions?: FetchNextPageOptions) => {
      const state = commonQueryClient.getQueryState(refOptions.current.queryKey)
      if (state && !state.error && state.data !== null && state.fetchStatus !== 'fetching') {
        return result.fetchNextPage(fetchNextPageOptions)
      }
    }) as typeof result.fetchNextPage,
    [],
  )

  return {
    ...result,
    fetchNextPage,
    hasNextPage:
      result.hasNextPage &&
      flatten(result.data?.pages.map((page) => (page as GetAllData<any>).results) || []).length <
        (last(result.data?.pages) as GetAllData<any>).count,
  }
}
