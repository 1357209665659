import { ButtonHTMLAttributes, FC, MouseEventHandler, ReactNode, RefObject, useEffect, useRef } from 'react'

import { useClickAway, useDebounceFn } from 'ahooks'
import { ReactComponent as NextSmallIcon } from 'assets/images/arrow-small.svg'
import { ReactComponent as NextMobileIcon } from 'assets/images/arrow.svg'
import { ReactComponent as NextMiniIcon } from 'assets/images/easy-arrow-mini.svg'
import { ReactComponent as NextIcon } from 'assets/images/easy-arrow.svg'
import { ReactComponent as DownloadMobileIcon } from 'assets/images/easy-download-mobile.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/easy-download.svg'
import { ReactComponent as HelpMobileIcon } from 'assets/images/easy-help-mobile.svg'
import { ReactComponent as HelpIcon } from 'assets/images/easy-help.svg'
import { ReactComponent as MarkMobileIcon } from 'assets/images/easy-mark-mobile.svg'
import { ReactComponent as MarkIcon } from 'assets/images/easy-mark.svg'
import newYearBall from 'assets/images/new-year-ball.png'
import { ReactComponent as PlusExtraMediumIcon } from 'assets/images/plus-extra-medium.svg'
import { ReactComponent as PlusIcon } from 'assets/images/plus-wide.svg'
import { ReactComponent as RepeatIcon } from 'assets/images/repeat.svg'
import { ReactComponent as SearchIcon } from 'assets/images/search.svg'
import { ReactComponent as WaitMobileIcon } from 'assets/images/wait-mobile.svg'
import { ReactComponent as WaitIcon } from 'assets/images/wait.svg'
import cx from 'clsx'
import { Animation, AnimationTypes } from 'components/template/Animation'
import { colorsAnimationBackground } from 'constants/animationBackground'
import { useOpenMenu } from 'hooks/useOpenMenu'
import { AnimationBackground } from 'ui/AnimationBackground'
import { Loader, LoaderColors, LoaderTypes } from 'ui/Loader'

import classes from './ButtonEasy.module.scss'

export enum ButtonEasyIcons {
  Next = 'next',
  NextSmall = 'nextSmall',
  NextMini = 'nextMini',
  NextMobile = 'nextMobile',
  Mark = 'mark',
  MarkMobile = 'markMobile',
  Download = 'download',
  Upload = 'upload',
  DownloadMobile = 'downloadMobile',
  Help = 'help',
  HelpMobile = 'helpMobile',
  Plus = 'plus',
  PlusExtraMedium = 'plusExtraMedium',
  Previous = 'previous',
  Wait = 'wait',
  WaitMobile = 'waitMobile',
  Repeat = 'repeat',
  Search = 'search',
}

export enum ButtonEasySizes {
  Default = 'default',
  Small = 'small',
  Tiny = 'tiny',
  Mini = 'mini',
}

export enum ButtonEasyPositions {
  Center = 'center',
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
}

const icons = {
  [ButtonEasyIcons.Next]: <NextIcon />,
  [ButtonEasyIcons.Previous]: <NextIcon style={{ transform: 'rotate(180deg)' }} />,
  [ButtonEasyIcons.NextSmall]: <NextSmallIcon />,
  [ButtonEasyIcons.NextMini]: <NextMiniIcon />,
  [ButtonEasyIcons.NextMobile]: <NextMobileIcon />,
  [ButtonEasyIcons.Mark]: <MarkIcon />,
  [ButtonEasyIcons.MarkMobile]: <MarkMobileIcon />,
  [ButtonEasyIcons.Download]: <DownloadIcon />,
  [ButtonEasyIcons.DownloadMobile]: <DownloadMobileIcon />,
  [ButtonEasyIcons.Upload]: <DownloadIcon style={{ transform: 'rotate(180deg)' }} />,
  [ButtonEasyIcons.Help]: <HelpIcon />,
  [ButtonEasyIcons.HelpMobile]: <HelpMobileIcon />,
  [ButtonEasyIcons.PlusExtraMedium]: <PlusExtraMediumIcon />,
  [ButtonEasyIcons.Plus]: <PlusIcon style={{ color: '#fff' }} />,
  [ButtonEasyIcons.Wait]: <WaitIcon />,
  [ButtonEasyIcons.WaitMobile]: <WaitMobileIcon />,
  [ButtonEasyIcons.Repeat]: <RepeatIcon />,
  [ButtonEasyIcons.Search]: <SearchIcon />,
}

export interface ButtonEasyProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  classNameWrap?: string
  className?: string
  classNamePopover?: string
  classNameCircle?: string
  classNameLabel?: string
  classNameWithChildren?: string
  children?: ReactNode
  icon?: ButtonEasyIcons
  size?: ButtonEasySizes
  label?: string
  onClick?: () => void
  loading?: boolean
  position?: ButtonEasyPositions
  disabled?: boolean
  isClickable?: boolean
  isLoadingClickable?: boolean
  preventDefaultClickOnPopover?: boolean
  initVisible?: boolean
  disableAnimationBackground?: boolean
  delayAnimationShow?: number
  iconLeft?: boolean
  showLabelAlways?: boolean
  onClickAway?: () => void
  refsAway?: RefObject<HTMLElement>[]
  onMouseEnterOutside?: () => void
  onMouseLeaveOutside?: () => void
  newYear?: boolean
}

export const ButtonEasy: FC<ButtonEasyProps> = ({
  classNameWrap,
  className,
  classNamePopover,
  classNameCircle,
  classNameLabel,
  classNameWithChildren = '',
  children,
  icon,
  size = ButtonEasySizes.Default,
  label,
  onClick,
  loading,
  position = ButtonEasyPositions.Center,
  disabled,
  isClickable = true,
  isLoadingClickable,
  preventDefaultClickOnPopover,
  initVisible,
  disableAnimationBackground,
  delayAnimationShow,
  iconLeft,
  showLabelAlways,
  onClickAway,
  refsAway,
  onMouseEnterOutside,
  onMouseLeaveOutside,
  newYear,
  ...props
}) => {
  const openMenu = useOpenMenu((state) => state.open)

  const refCircle = useRef<HTMLDivElement>(null)
  const refLabelWrap = useRef<HTMLSpanElement>(null)
  const refLabel = useRef<HTMLSpanElement>(null)
  const refPopover = useRef<HTMLDivElement>(null)
  const refCont = useRef<HTMLDivElement>(null)

  useClickAway(() => onClickAway?.(), [refCont, ...(refsAway || [])])

  const { run: runMouseLeave, cancel: cancelMouseEnter } = useDebounceFn(
    () => {
      onMouseLeaveOutside?.()
      if (
        size === ButtonEasySizes.Default &&
        refLabelWrap.current &&
        refLabel.current &&
        refCircle.current &&
        !showLabelAlways
      ) {
        refLabelWrap.current.style.width = '0px'
        refLabelWrap.current.classList.remove(classes.show)
        refCircle.current.classList.remove(classes.circleHover)
        if (refPopover.current) {
          refPopover.current.classList.remove(classes.popoverHover)
        }
      }
    },
    { wait: 100 },
  )

  const onMouseEnter = () => {
    onMouseEnterOutside?.()
    if (
      size === ButtonEasySizes.Default &&
      refLabelWrap.current &&
      refLabel.current &&
      refCircle.current &&
      !loading &&
      !showLabelAlways
    ) {
      cancelMouseEnter()
      if (
        refPopover.current &&
        window.innerWidth - refLabel.current.offsetWidth < refPopover.current.offsetWidth + 640
      ) {
        refPopover.current.classList.add(classes.popoverHover)
      }
      refLabelWrap.current.style.width = `${refLabel.current.offsetWidth}px`
      refLabelWrap.current.classList.add(classes.show)
      refCircle.current.classList.add(classes.circleHover)
    }
  }

  const onMouseLeave = () => {
    runMouseLeave()
  }

  const onPopoverClick: MouseEventHandler = (event) => {
    if (preventDefaultClickOnPopover) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  useEffect(() => {
    if (loading && !showLabelAlways) {
      runMouseLeave()
    }
  }, [loading])

  if (!openMenu && position === ButtonEasyPositions.BottomLeft) {
    return null
  }

  return (
    <Animation
      className={cx(classes.wrap, classes[position], classNameWrap, {
        [classNameWithChildren]: children,
        [classes.small]: size === ButtonEasySizes.Small,
        [classes.tiny]: size === ButtonEasySizes.Tiny,
        [classes.mini]: size === ButtonEasySizes.Mini,
        [classes.newYear]: newYear,
      })}
      classNameHide={classes.hide}
      classNameIsShow={classes.show}
      delayAnimationShow={delayAnimationShow}
      duration={300}
      initVisible={initVisible}
      type={AnimationTypes.ScaleInMax}
    >
      <div className={cx(classes.cont, className, icon && classes[icon])} ref={refCont}>
        <button className={cx(classes.circleCont)} disabled={disabled} {...props}>
          {newYear && <img alt="" className={classes.newYearBall} src={newYearBall} />}
          <div
            className={cx(classes.circle, classNameCircle, {
              [classes.loading]: loading,
              [classes.disabled]: disabled,
              [classes.isClickable]: isClickable && (!loading || isLoadingClickable),
              [classes.isLoadingClickable]: isLoadingClickable,
            })}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={refCircle}
            style={!newYear ? { backgroundColor: colorsAnimationBackground[0] } : undefined}
          >
            {!disableAnimationBackground && (
              <Animation delayAnimationShow={100} duration={1000} type={AnimationTypes.Opacity}>
                <AnimationBackground className={classes.canvas} />
              </Animation>
            )}
            {label && (
              <span className={cx(classes.labelWrap, { [classes.showAlways]: showLabelAlways })} ref={refLabelWrap}>
                <span
                  className={cx(classes.label, classNameLabel, {
                    [classes.labelWithIcon]: icon,
                    [classes.labelRight]: iconLeft,
                  })}
                  ref={refLabel}
                >
                  {label}
                </span>
              </span>
            )}
            {icon && (
              <div className={cx(classes.icon, { [classes.iconLeft]: iconLeft })}>
                <Animation className={classes.iconAnimation} duration={100} initVisible type={AnimationTypes.ScaleIn}>
                  {position === ButtonEasyPositions.BottomLeft && icon === ButtonEasyIcons.Next
                    ? icons[ButtonEasyIcons.NextMobile]
                    : icons[icon]}
                </Animation>
              </div>
            )}
            <Animation className={classes.loaderAnim} delayAnimationShow={200} type={AnimationTypes.ScaleIn}>
              {loading && (
                <Loader
                  className={classes.loader}
                  classNameCont={classes.loaderCont}
                  classNameWheel={classes.loaderWheel}
                  color={LoaderColors.White}
                  type={LoaderTypes.Spinner}
                />
              )}
            </Animation>
          </div>
          {children && (
            <div className={cx(classes.popover, classNamePopover)} onClick={onPopoverClick} ref={refPopover}>
              {children}
            </div>
          )}
        </button>
      </div>
    </Animation>
  )
}
