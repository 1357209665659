import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = 'gitlab-deploy'

interface WebSockets {
  updating: NoParamEndpointConstructor
}

export const updateSystemOptiWebSocketEndPoint: WebSockets = {
  updating: () => baseUrl,
}
