import { requestBroadcastTokens } from 'config/auth/requestBroadcastTokens'
import { axios, setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { storageRefreshName } from 'constants/storage'
import { GetOneResponse } from 'interfaces/api.interfaces'
import { RefreshData, TokenResponse } from 'interfaces/auth.interfaces'
import { authEndpoints } from 'modules/auth/api'
import { useResetPasswordTokens } from 'modules/resetPassword/store'

const refresh = async (form: RefreshData): GetOneResponse<TokenResponse> => {
  const { data } = await axios.request<TokenResponse>({
    method: 'POST',
    url: authEndpoints.refresh(),
    data: form,
  })
  return data
}

export const refreshTokens = async () => {
  const resetPasswordTokens = useResetPasswordTokens.getState()
  const refreshToken = storage.getRefresh() || resetPasswordTokens.refresh

  const tokens = await refresh({ refresh: refreshToken })
    .catch(requestBroadcastTokens)
    .catch((error) => {
      throw { ...error, code: 'refreshError' }
    })

  if (resetPasswordTokens.access) {
    resetPasswordTokens.setAccess(tokens.access)
    resetPasswordTokens.setRefresh(tokens.refresh)
    setAxiosAuthorizationToken(tokens.access)
  } else {
    const tokenLS = window.localStorage.getItem(storageRefreshName)
    storage.setToken(tokens.access, !!tokenLS)
    storage.setRefresh(tokens.refresh, !!tokenLS)
    setAxiosAuthorizationToken(tokens.access)
  }

  return { tokens }
}
