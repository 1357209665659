import { FC, useState } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg'
import cx from 'clsx'
import { NavLink } from 'components/template/NavLink'
import { NavTitle } from 'components/template/NavTitle'
import { SlideToggle, SlideToggleTypes } from 'ui/SlideToggle'

import { ItemMenuDeploy } from './deployMenu'
import classes from './NavMenuDeploy.module.scss'

interface Props {
  menuItem: ItemMenuDeploy
}

export const NavMenuDeployItem: FC<Props> = ({ menuItem }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(true)
  const toggleMenuOpen = () => setIsSubMenuOpen((prev) => !prev)

  return (
    <>
      {menuItem?.items && (
        <>
          <NavTitle
            className={cx('menuItem', classes.disabled)}
            icon={<ArrowIcon />}
            isActive={isSubMenuOpen}
            onClick={toggleMenuOpen}
          >
            {menuItem.label}
          </NavTitle>
          <SlideToggle classNameWrap={classes.subMenu} open={isSubMenuOpen} type={SlideToggleTypes.NoTransform}>
            {menuItem.items.map((menuChildItem) => (
              <NavLink className={cx('menuItem', classes.subItem)} key={menuChildItem.id} path={menuChildItem.path}>
                {menuChildItem.label}
              </NavLink>
            ))}
          </SlideToggle>
        </>
      )}

      {menuItem?.path && <NavLink path={menuItem.path}>{menuItem.label}</NavLink>}
    </>
  )
}
