import { devtools } from 'config/devtools'
import { PnlRow } from 'prgm/plTable/interfaces'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UsePnlTable {
  rows: PnlRow[]
  open: boolean
  setOpen: (open: boolean) => void
  setPnlTableRows: (rows: PnlRow[]) => void
  clearPnlTable: () => void
}

export const usePnlTable = create<UsePnlTable>()(
  devtools(
    persist(
      (set) => ({
        rows: [],
        open: true,
        setOpen: (open) => set({ open }),
        setPnlTableRows: (rows) => set({ rows }),
        clearPnlTable: () => set({ rows: [], open: true }),
      }),
      {
        name: 'prgmPnlTable',
      },
    ),
    {
      store: 'prgmPnlTable',
    },
  ),
)
