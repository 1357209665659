import { i18n } from 'config/i18n'
import { Routes } from 'routes/routes'

interface ChildItemMenu {
  id: string
  label: string
  path: string
}

export interface ItemMenuDeploy {
  id: string
  label: string
  items?: ChildItemMenu[]
  path?: string
}

export const getDeployMenu = (): ItemMenuDeploy[] => [
  {
    id: 'session',
    label: i18n.get('deploy.navMenu.session'),
    items: [
      { id: 'management', label: i18n.get('deploy.navMenu.session.management'), path: Routes.DeploySessionManagement },
    ],
  },
  {
    id: 'scenarios',
    label: i18n.get('deploy.navMenu.scenarios'),
    items: [
      {
        id: 'management',
        label: i18n.get('deploy.navMenu.scenarios.management'),
        path: Routes.DeployScenarioManagement,
      },
      { id: 'history', label: i18n.get('deploy.navMenu.scenarios.history'), path: Routes.DeployScenarioHistory },
      {
        id: 'settings',
        label: i18n.get('deploy.navMenu.scenarios.settings'),
        path: Routes.DeployScenarioSettingsEmpty,
      },
    ],
  },
  {
    id: 'deployment',
    label: i18n.get('deploy.navMenu.deployment'),
    items: [
      {
        id: 'management',
        label: i18n.get('deploy.navMenu.deployment.allocation'),
        path: Routes.DeployStockAllocation,
      },
      {
        id: 'history',
        label: i18n.get('deploy.navMenu.deployment.optimization'),
        path: Routes.DeployTransportOptimization,
      },
    ],
  },
  {
    id: 'reporting',
    label: i18n.get('deploy.navMenu.reporting'),
    items: [
      {
        id: 'management',
        label: i18n.get('deploy.navMenu.reporting.pped'),
        path: Routes.DeployReporting,
      },
      {
        id: 'history',
        label: i18n.get('deploy.navMenu.reporting.executive'),
        path: Routes.DeployExecutive,
      },
    ],
  },
]
