import { FC, useEffect } from 'react'

import { ReactComponent as DragIcon } from 'assets/images/drag-strong.svg'
import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { Widget } from 'jume/editMode/api'
import { parseWidgetTitle } from 'jume/editMode/helpers/parseWidgetTitle'
import { WidgetTypes } from 'jume/editMode/interfaces'

import classes from './EditModeMenuWidget.module.scss'

interface EditModeMenuWidgetProps {
  className?: string
  item: Widget
  setCanDragging?: (value: boolean) => void
  isDeactivatedRecWidget?: boolean
}

export const EditModeMenuWidget: FC<EditModeMenuWidgetProps> = ({
  className,
  item,
  setCanDragging,
  isDeactivatedRecWidget,
}) => {
  const translate = useTranslate()

  const itemClass = Object.entries(WidgetTypes).find((elem) => elem[1] === item.code)?.[0] ?? ''
  const itemClassCamelCased = itemClass.charAt(0).toLowerCase() + itemClass.slice(1)

  useEffect(() => {
    setCanDragging?.(true)
  }, [])

  return (
    <div className={cx(classes.wrap, className)}>
      <div className={classes.title}>{parseWidgetTitle(item.code)}</div>
      <DragIcon className={classes.dragButton} />
      {item?.code &&
        (isDeactivatedRecWidget ? (
          <div className={classes.notActiveInfo}>{translate('editMode.recommendationsActivate')}</div>
        ) : (
          <div className={cx(classes.image, classes[itemClassCamelCased])} />
        ))}
    </div>
  )
}
