import { redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { setAxiosAuthorizationToken } from 'config/axios'
import { i18n } from 'config/i18n'
import storage from 'config/storage'
import { BrowserHistory } from 'history'
import { authApi } from 'modules/auth/api'
import { Routes } from 'routes/routes'

export const ssoLoader = async (history?: BrowserHistory) => {
  const search = history ? history.location.search : window.location.search
  const params = new URLSearchParams(search)
  const ssoCode = params.get('code')

  if (ssoCode) {
    try {
      window.history.replaceState(null, '', window.location.pathname)
      const { accessToken, refreshToken } = await authApi.getAuthSsoTokens(ssoCode)
      storage.setToken(accessToken, true)
      storage.setRefresh(refreshToken, true)
      setAxiosAuthorizationToken(accessToken)
    } catch (e) {
      toast.error(i18n.get('ssoLoginError'))
      if (history) {
        history.push(Routes.Login)
      } else {
        redirect(Routes.Login)
      }
    }
  }
}
