import { RefObject, useEffect } from 'react'

export const useResizeObserver = (
  el: RefObject<HTMLElement> | undefined,
  cb: (width: number, height: number) => void,
  deps?: any[],
) => {
  useEffect(() => {
    if (!el?.current) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { clientWidth, clientHeight } = entry.target
        cb(clientWidth, clientHeight)
      })
    })
    resizeObserver.observe(el.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [el, ...(deps || [])])
}
