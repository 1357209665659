import { Dispatch, SetStateAction } from 'react'

import { devtools } from 'config/devtools'
import { i18n } from 'config/i18n'
import { SELECT_ALL } from 'constants/components'
import { CustomerSplit } from 'prgm/createPromo/api'
import { CustomerTableRow } from 'prgm/customersTable/interfaces'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseCustomerTable {
  rows: CustomerTableRow[]
  total: CustomerTableRow
  selectedIds: (number | string)[]
  disabledIds: (number | string)[]
  totalId: number
  x5Flag: boolean
  initialValues: {
    id: number
    baseline: number
    volume: number
  }[]
  open: boolean

  setSelectedIds: Dispatch<SetStateAction<(number | string)[]>>
  clear: () => void
  setCustomerTable: (rows: CustomerSplit[], total: CustomerTableRow, x5Flag: boolean) => void
  setBaseline: (id: number, value: number) => void
  setVod: (id: number, value: number) => void
  setOpen: (open: boolean) => void
}

export const useCustomerTable = create<UseCustomerTable>()(
  devtools(
    persist(
      (set, getState) => ({
        rows: [],
        total: {} as CustomerTableRow,
        selectedIds: [],
        disabledIds: [],
        totalId: 0,
        initialValues: [],
        x5Flag: false,
        open: true,

        setOpen: (open) => set({ open }),

        setCustomerTable: (newRows, total, x5Flag) => {
          set(() => {
            const rowsWithIds: CustomerTableRow[] = newRows.map((row, index) => ({
              ...row,
              id: index,
              isFooter: false,
              baseline: Number(row.baseline.toFixed(2)),
              volume: Number(row.volume.toFixed(2)),
            }))
            const initialValues = newRows.map((row, index) => ({
              id: index,
              baseline: Number(row.baseline.toFixed(2)),
              volume: Number(row.volume.toFixed(2)),
            }))
            const normalizedTotal: CustomerTableRow = {
              ...total,
              baseline: Number(total.baseline.toFixed(2)),
              volume: Number(total.volume.toFixed(2)),
            }

            if (x5Flag) {
              total.customerCode = i18n.get('prgm.create.customerCodeX5')
            }

            return {
              rows: rowsWithIds,
              totalId: newRows.length,
              total: {
                ...normalizedTotal,
                id: newRows.length,
                isFooter: true,
              },
              initialValues,
              selectedIds: [],
              disabledIds: [],
              x5Flag,
            }
          })

          const selectedIds = getState()
            .rows.filter((item) => item.isSelected)
            .map((item) => item.id)
          getState().setSelectedIds(selectedIds)
        },
        setSelectedIds: (state) => {
          set((prev) => {
            const selectedIds = typeof state === 'function' ? state(prev.selectedIds) : state
            const parentId = getState().totalId
            const x5Flag = getState().x5Flag
            const total = getState().total
            const rowsLength = getState().rows.length
            const isParentSelected = selectedIds.includes(parentId)
            prev.rows.forEach((row) => {
              row.isSelected = selectedIds.includes(row.id)
            })

            if (!x5Flag) {
              if (selectedIds.length < rowsLength) {
                total.customerCode = i18n.get('prgm.create.customerCodeSome')
              } else {
                total.customerCode = i18n.get('prgm.create.customerCodeAll')
              }
            }

            if (isParentSelected) {
              return {
                selectedIds,
                disabledIds: selectedIds.length ? [SELECT_ALL] : [],
              }
            }

            return {
              selectedIds,
              disabledIds: selectedIds.length ? [parentId] : [],
              total: { ...total },
            }
          })
        },
        setBaseline: (id, value) => {
          set(({ rows, initialValues }) => {
            const foundRow = rows.find((row) => row.id === id)
            const initialBaseline = initialValues.find((row) => row.id === id)?.baseline

            if (foundRow) {
              foundRow.baseline = value
              foundRow.isManualBaseline = initialBaseline !== value
            }

            return { rows }
          })
        },
        setVod: (id, value) => {
          set(({ rows, initialValues }) => {
            const foundRow = rows.find((row) => row.id === id)
            const initialVolume = initialValues.find((row) => row.id === id)?.volume

            if (foundRow) {
              foundRow.volume = value
              foundRow.isManualVolume = initialVolume !== value
            }

            return { rows }
          })
        },
        clear: () =>
          set({
            selectedIds: [],
            disabledIds: [],
            totalId: 0,
            rows: [],
            total: {} as CustomerTableRow,
            initialValues: [],
            x5Flag: false,
            open: true,
          }),
      }),
      { name: 'prgmCustomersTable' },
    ),
    {
      store: 'prgmCustomersTable',
    },
  ),
)
