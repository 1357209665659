import { HierarchyTree, MasterDataHierarchyItem } from 'interfaces/common.interfaces'
import { FilterItem, OptionTreeItem } from 'interfaces/components.interfaces'
import { flatten, isEqual } from 'lodash'
import { toArray } from 'packages/helper'
import { memoizeCallback } from 'utils/memoize'

export const parseChildrenElements = (filters?: HierarchyTree[], hierarchyId?: number): OptionTreeItem[] =>
  filters?.map((item) => ({
    value: item.id,
    label: item.title ?? item.code,
    code: item.code,
    items: item.children?.length ? parseChildrenElements(item.children, hierarchyId) : [],
    parentId: item.parentId,
    parentIds: item.parentIds,
    hierarchyId,
  })) ?? []

const addParentIds = (options: OptionTreeItem[], parentIds: number[] = [], hierarchyId?: number): OptionTreeItem[] =>
  options.map((option) => ({
    ...option,
    parentIds,
    hierarchyId,
    items: option.items ? addParentIds(option.items, [...parentIds, Number(option.value)], hierarchyId) : undefined,
  }))

const compareTrees = (oldTree?: Record<number, OptionTreeItem[]>, newTree?: Record<number, OptionTreeItem[]>) => {
  const compareTreesArray = (oldTreeArray: OptionTreeItem[], newTreeArray: OptionTreeItem[]) => {
    if (newTreeArray.length > oldTreeArray.length) {
      return false
    }
    for (let i = 0; i < oldTreeArray.length; i++) {
      if (!newTreeArray[i]) {
        continue
      }
      if (newTreeArray[i].value !== oldTreeArray[i].value) {
        return false
      }
      const childrenCompare = compareTreesArray(oldTreeArray[i]?.items || [], newTreeArray[i]?.items || [])
      if (!childrenCompare) {
        return false
      }
    }
    return true
  }

  if (!oldTree || !newTree) {
    return false
  }

  const keys = Object.keys(oldTree)
  for (let i = 0; i < keys.length; i++) {
    const key = Number(keys[i])
    const isEqualTrees = compareTreesArray(oldTree[key] || [], newTree[key] || [])
    if (!isEqualTrees) {
      return false
    }
  }

  return true
}

export const parseFilters = (
  hierarchyFilters: MasterDataHierarchyItem[],
  filters?: Record<number, OptionTreeItem[]>,
  currentId?: number,
  searchFilters?: HierarchyTree[],
  showProductOnly?: boolean,
  changedHierarchyLabel?: string,
): FilterItem[] => [
  ...hierarchyFilters
    .filter((hierarchy) => (showProductOnly ? hierarchy.isProduct && hierarchy.isActive : true))
    .map((item) => ({
      id: item.id,
      name: item.name,
      label: changedHierarchyLabel ? changedHierarchyLabel : item.name,
      showSearch: true,
      isTree: true,
      multiple: true,
      options: memoizeCallback(
        () =>
          currentId === item.id
            ? searchFilters
              ? parseChildrenElements(searchFilters, item.id)
              : filters
              ? addParentIds(flatten(Object.values(filters)), [], item.id)
              : undefined
            : undefined,
        'filterOptions',
        [item.id],
        {
          isIgnoredValue: (value) => value === undefined || isEqual(value, []),
          conditionFn: () => currentId === item.id,
          deepCompare: [filters, searchFilters],
          deepCompareFn: (oldDeepCompare, newDeepCompare) => {
            if (item.id !== currentId) {
              return true
            }

            const compareFilters = compareTrees(oldDeepCompare?.[0], newDeepCompare?.[0])
            if (!compareFilters) {
              return false
            }

            return isEqual(oldDeepCompare?.[1], newDeepCompare?.[1])
          },
        },
      ),
    })),
]

export const findOptionDeep = (value: string | number, options?: OptionTreeItem[]): OptionTreeItem | undefined => {
  if (!options) {
    return
  }
  for (const option of options) {
    if (option.value === value) {
      return option
    }
    const foundDeep = findOptionDeep(value, option.items)
    if (foundDeep) {
      return foundDeep
    }
  }
}

export const findParentsDeep = (
  currentItem?: OptionTreeItem,
  options?: OptionTreeItem[],
): (string | number | null)[] | undefined => {
  if (!options && !currentItem) {
    return
  }

  const parents: (string | number | null)[] = []

  const addParents = (value?: string | number | null) => {
    if (value) {
      parents.push(value)
      const newParent = findOptionDeep(value, options)
      if (newParent?.value) {
        parents.push(newParent.value)
        addParents(newParent?.parentValue)
      }
    }
  }

  addParents(currentItem?.parentValue)

  return parents
}

export const setChildrenValues = (array?: OptionTreeItem[]) => {
  const childrenValues: (string | number | null | undefined)[] = []

  const addValueToArray = (arr: OptionTreeItem[] | undefined) =>
    arr?.forEach((item) => {
      childrenValues.push(item.value)
      if (item.items?.length) {
        addValueToArray(item.items)
      }
    })

  addValueToArray(array)

  return childrenValues
}

export const fillFilterDeep = (
  parentArr: OptionTreeItem[],
  arr: Record<number, OptionTreeItem[]>,
  parentId: number,
  currentChildrenPage: number,
): OptionTreeItem[] => {
  const newArr = parentArr ? [...parentArr] : []
  const foundItemIndex = parentArr?.findIndex((item) => item.value === parentId)

  if (foundItemIndex !== undefined && foundItemIndex > -1) {
    const foundItem = parentArr.find((item) => item.value === parentId)
    const previousItems = flatten(Object.values(foundItem?.items ? foundItem.items : []))

    if (
      previousItems[previousItems.length - 1]?.value !==
      arr[currentChildrenPage][arr[currentChildrenPage].length - 1]?.value
    ) {
      newArr[foundItemIndex].items = [...previousItems, ...arr[currentChildrenPage]]
    }
    return newArr
  }

  return newArr.map((item) => {
    if (item.items?.length) {
      return { ...item, items: fillFilterDeep(item.items, arr, parentId, currentChildrenPage) }
    }
    return item
  })
}

export const findCheckedDeep = (
  element: OptionTreeItem,
  checkedValues?: string | number | null | (string | number)[],
): boolean => {
  if (!element.items) {
    return false
  }

  const isFound = element.items.findIndex((item: OptionTreeItem) => !!toArray(checkedValues)?.includes(item.value)) > -1

  if (!isFound) {
    return element.items.findIndex((item) => findCheckedDeep(item, checkedValues)) > -1
  }

  return isFound
}

export const findCheckedDeepByParentIds = (
  element: OptionTreeItem,
  selectedParentIds?: Record<number, number[]>,
  checkedValues: string | number | null | (string | number)[] = [],
  selectedOptions?: OptionTreeItem[],
): boolean => {
  const parentIds = Array.from(
    new Set([
      ...toArray(checkedValues)
        .map((value) => selectedParentIds?.[value])
        .flat(),
      ...(selectedOptions || []).map((item) => item.parentIds).flat(),
    ]),
  )
  return !!parentIds?.includes(element.value as number)
}

export const findCheckedAllChildren = (
  element: OptionTreeItem,
  checkedValues?: string | number | null | (string | number)[],
): boolean => {
  if (!element.items) {
    return false
  }

  const isAllChecked = !(
    element.items.findIndex((item: OptionTreeItem) => !toArray(checkedValues)?.includes(item.value)) > -1
  )

  if (!isAllChecked) {
    return !(element.items.findIndex((item: OptionTreeItem) => !findCheckedAllChildren(item, checkedValues)) > -1)
  }

  return isAllChecked
}
