export enum Permissions {
  addActualShipment = 'add_actualshipment',
  addPrice = 'add_price',
  deletePrice = 'delete_price',
  addTarget = 'add_target',
  deleteTarget = 'delete_target',
  addDataSourceVersion = 'add_datasourceversion',
  canViewDataStatuses = 'can_view_data_statuses',
  viewDataSources = 'view_datasource',
  deleteActualShipment = 'delete_actualshipment',
  deleteDataSourceVersion = 'delete_datasourceversion',
  sendForCalculationForecast = 'send_for_calculation_forecastscenarioresult',
  canDownloadForecastExcel = 'can_download_forecast_excel',
  viewForecastScenario = 'view_forecastscenario',
  viewForecastScenarioResult = 'view_forecastscenarioresult',
  setIsProposedForecast = 'set_is_proposed_forecastscenario',
  canEditMasterData = 'can_edit_masterdata',
  canDeletePromoScenario = 'delete_promoscenario',
  canChangePromoScenario = 'change_promoscenario',
  canDeleteReplenScenario = 'delete_replenscenario',
  canSetDefaultWidgetsOrder = 'can_set_default_widgets_order',
  canDeleteRtmScenario = 'delete_rtmscenario',
  canChangeRtmScenario = 'change_rtmscenario',
  canDeleteTetrisScenario = 'delete_tetrisscenario',
  canChangeTetrisScenario = 'change_tetrisscenario',
  canDeleteCfrScenario = 'delete_cfrscenario',
  canChangeCfrScenario = 'change_cfrscenario',
  viewEditForecastDashboard = 'view_edit_forecast_dashboard',
  allowCreatePromo = 'allow_create_promo',
  editCreateDb = 'edit_create_dashboard',
  editSearchDb = 'edit_search_dashboard',
  editPulseDb = 'edit_pulse_dashboard',
  editApproveDb = 'edit_move_approve_dashboard',
  editReportsDb = 'edit_reports_dashboard',
  editDatahubDb = 'edit_datahub_dashboard',

  readCreateDb = 'read_create_dashboard',
  readSearchDb = 'read_search_dashboard',
  readPulseDb = 'read_pulse_dashboard',
  readApproveDb = 'read_move_approve_dashboard',
  readReportsDb = 'read_reports_dashboard',
  readDatahubDb = 'read_datahub_dashboard',

  runJobsDeploy = 'run_jobs_deploy',
  runJobsPrgm = 'run_jobs_prgm',
  runJobsDemand = 'run_jobs_demand',
  readJobsDbDeploy = 'read_jobs_dashboard_deploy',
  readJobsDbPrgm = 'read_jobs_dashboard_prgm',
  readJobsDbDemand = 'read_jobs_dashboard_demand',
  editJobsDbDeploy = 'edit_jobs_dashboard_deploy',
  editJobsDbPrgm = 'edit_jobs_dashboard_prgm',
  editJobsDbDemand = 'edit_jobs_dashboard_demand',

  // Prgm
  editPromoNameCreateDashboard = 'edit_promo_name_create_dashboard',
  editProjectCreateDashboard = 'edit_project_create_dashboard',
  editPromoTypeCreateDashboard = 'edit_promo_type_create_dashboard',
  editBudgetTypeCreateDashboard = 'edit_budget_type_create_dashboard',
  editMechanicCreateDashboard = 'edit_mechanic_create_dashboard',
  editOnInvoicePcCreateDashboard = 'edit_on_invoice_pc_create_dashboard',
  editOffInvoicePcCreateDashboard = 'edit_off_invoice_pc_create_dashboard',
  editConditionTypeDescriptionCreateDashboard = 'edit_condition_type_description_create_dashboard',
  editConditionCodeCreateDashboard = 'edit_condition_code_create_dashboard',
  editRrspCreateDashboard = 'edit_rrsp_create_dashboard',
  editNrspCreateDashboard = 'edit_nrsp_create_dashboard',
  editRegionCreateDashboard = 'edit_region_create_dashboard',
  editChannelCreateDashboard = 'edit_channel_create_dashboard',
  editChainCreateDashboard = 'edit_chain_create_dashboard',
  editMarketCategoryCreateDashboard = 'edit_market_category_create_dashboard',
  editBrandCreateDashboard = 'edit_brand_create_dashboard',
  editCategoryCreateDashboard = 'edit_category_create_dashboard',
  editSubfamilyCreateDashboard = 'edit_subfamily_create_dashboard',
  editFtSkuCreateDashboard = 'edit_ft_sku_create_dashboard',
  editDaysBeforePromoStartCreateDashboard = 'edit_days_before_promo_start_create_dashboard',
  editDaysShipmentCreateDashboard = 'edit_days_shipment_create_dashboard',
  editWdChangesCreateDashboard = 'edit_wd_changes_create_dashboard',
  editNoBlCalculationCreateDashboard = 'edit_no_bl_calculation_create_dashboard',
  editVodCreateDashboard = 'edit_vod_create_dashboard',
  editBlCreateDashboard = 'edit_bl_create_dashboard',
  editCommentsCreateDashboard = 'edit_comments_create_dashboard',
  editStoreStartDateCreateDashboard = 'edit_store_start_date_create_dashboard',
  editStoreEndDateCreateDashboard = 'edit_store_end_date_create_dashboard',
  readPromoIdCreateDashboard = 'read_promo_id_create_dashboard',
  readPromoStatusCreateDashboard = 'read_promo_status_create_dashboard',
  readPromoNameCreateDashboard = 'read_promo_name_create_dashboard',
  readSubmitDateCreateDashboard = 'read_submit_date_create_dashboard',
  readSetupDateCreateDashboard = 'read_setup_date_create_dashboard',
  readProjectCreateDashboard = 'read_project_create_dashboard',
  readPromoTypeCreateDashboard = 'read_promo_type_create_dashboard',
  readBudgetTypeCreateDashboard = 'read_budget_type_create_dashboard',
  readMechanicCreateDashboard = 'read_mechanic_create_dashboard',
  readOnInvoicePcCreateDashboard = 'read_on_invoice_pc_create_dashboard',
  readOffInvoicePcCreateDashboard = 'read_off_invoice_pc_create_dashboard',
  readConditionTypeDescriptionCreateDashboard = 'read_condition_type_description_create_dashboard',
  readConditionCodeCreateDashboard = 'read_condition_code_create_dashboard',
  readTtOnCreateDashboard = 'read_tt_on_create_dashboard',
  readTtOffCreateDashboard = 'read_tt_off_create_dashboard',
  readRegularPriceVatCreateDashboard = 'read_regular_price_vat_create_dashboard',
  readPromoPriceVatCreateDashboard = 'read_promo_price_vat_create_dashboard',
  readRrspAbsCreateDashboard = 'read_rrsp_abs_create_dashboard',
  readNrspAbsCreateDashboard = 'read_nrsp_abs_create_dashboard',
  readRegionCreateDashboard = 'read_region_create_dashboard',
  readChannelCreateDashboard = 'read_channel_create_dashboard',
  readChainCreateDashboard = 'read_chain_create_dashboard',
  readMarketCategoryCreateDashboard = 'read_market_category_create_dashboard',
  readBrandCreateDashboard = 'read_brand_create_dashboard',
  readCategoryCreateDashboard = 'read_category_create_dashboard',
  readSubfamilyCreateDashboard = 'read_subfamily_create_dashboard',
  readFtSkuCreateDashboard = 'read_ft_sku_create_dashboard',
  readStoreStartDateCreateDashboard = 'read_store_start_date_create_dashboard',
  readStoreEndDateCreateDashboard = 'read_store_end_date_create_dashboard',
  readMonthCreateDashboard = 'read_month_create_dashboard',
  readQuarterCreateDashboard = 'read_quarter_create_dashboard',
  readYearCreateDashboard = 'read_year_create_dashboard',
  readDaysBeforePromoStartCreateDashboard = 'read_days_before_promo_start_create_dashboard',
  readDaysShipmentCreateDashboard = 'read_days_shipment_create_dashboard',
  readShipStartCreateDashboard = 'read_ship_start_create_dashboard',
  readShipEndCreateDashboard = 'read_ship_end_create_dashboard',
  readWeekOfPromoStartCreateDashboard = 'read_week_of_promo_start_create_dashboard',
  readWeekNumberCreateDashboard = 'read_week_number_create_dashboard',
  readWdChangesCreateDashboard = 'read_wd_changes_create_dashboard',
  readCommentsCreateDashboard = 'read_comments_create_dashboard',
  readCustomerSplitCreateDashboard = 'read_customer_split_create_dashboard',
  copyPromoSearch = 'copy_promo_search',
  editPromoSearch = 'edit_promo_search',
  recallPromoSearch = 'recall_promo_search',
  createPromoCreate = 'create_promo_create',
  savePromoCreate = 'save_promo_create',
  approvePromoCreate = 'approve_promo_create',
  rejectPromoCreate = 'reject_promo_create',
  copyPromoCreate = 'copy_promo_create',
  deletePromoCreate = 'delete_promo_create',
  sendToSetUpPromoCreate = 'send_to_set_up_promo_create',
  recallPromoCreate = 'recall_promo_create',
  pushSkuSplitPromoCreate = 'push_sku_split_promo_create',
  cancelPromoCreate = 'cancel_promo_create',
  sendPromoCreate = 'send_promo_create',
  readPromoCreateDashboard = 'read_promo_create_dashboard',
  readPnlCreateDashboard = 'read_pnl_create_dashboard',
  readSkuSplitCreateDashboard = 'read_sku_split_create_dashboard',
  movePromoMoveApprove = 'move_promo_move_approve',
  rejectPromoMoveApprove = 'reject_promo_move_approve',
  approvePromoMoveApprove = 'approve_promo_move_approve',
  readMasterExtract = 'read_master_extract',
  readMasterExtractLight = 'read_master_extract_light',
  readGpsReport = 'read_gps_report',
  readSellInPromoTrends = 'read_sell_in_promo_trends',
  readPromoWorkflowReport = 'read_promo_workflow_report',
  readI2CReport = 'read_i2c_report',
  readDiscountReportZu1P = 'read_discount_report_zu1p',
  readDiscountReportZu2P = 'read_discount_report_zu2p',
  readDiscountReportZuup = 'read_discount_report_zuup',
  readDiscountReportZuvp = 'read_discount_report_zuvp',
  readDiscountReportZuwp = 'read_discount_report_zuwp',
  readDiscountReportZuxp = 'read_discount_report_zuxp',
  readDiscountReportZuyp = 'read_discount_report_zuyp',
  readDiscountCreation = 'read_discount_creation',
  readPromoPlan = 'read_promo_plan',
  readPromoPlanSfa = 'read_promo_plan_sfa',
  readUpliftReport = 'read_uplift_report',
  readX5Md = 'read_x5_md',
  editMasterExtract = 'edit_master_extract',
  editMasterExtractLight = 'edit_master_extract_light',
  editGpsReport = 'edit_gps_report',
  editSellInPromoTrends = 'edit_sell_in_promo_trends',
  editPromoWorkflowReport = 'edit_promo_workflow_report',
  editI2CReport = 'edit_i2c_report',
  editDiscountReportZu1P = 'edit_discount_report_zu1p',
  editDiscountReportZu2P = 'edit_discount_report_zu2p',
  editDiscountReportZuup = 'edit_discount_report_zuup',
  editDiscountReportZuvp = 'edit_discount_report_zuvp',
  editDiscountReportZuwp = 'edit_discount_report_zuwp',
  editDiscountReportZuxp = 'edit_discount_report_zuxp',
  editDiscountReportZuyp = 'edit_discount_report_zuyp',
  editDiscountCreation = 'edit_discount_creation',
  editPromoPlan = 'edit_promo_plan',
  editPromoPlanSfa = 'edit_promo_plan_sfa',
  editUpliftReport = 'edit_uplift_report',
  editX5Md = 'edit_x5_md',
  editX5MdHub = 'edit_x5_md_hub',
  editChainRegionMdHub = 'edit_chain_region_md_hub',
  editFtSkuMdHub = 'edit_ft_sku_md_hub',
  editFinanceHub = 'edit_finance_hub',
  editPriceListHub = 'edit_price_list_hub',
  editPromoGuideHub = 'edit_promo_guide_hub',
  editWdShelfRecoPriceHub = 'edit_wd_shelf_reco_price_hub',
  editNoBlRecalculationHub = 'edit_no_bl_recalculation_hub',
  editPromoVolumeHub = 'edit_promo_volume_hub',
  editPromoTemplateHub = 'edit_promo_template_hub',
  editPmdStatusHub = 'edit_pmd_status_hub',
  editCapacityMdHub = 'edit_capacity_md_hub',
  readX5MdHub = 'read_x5_md_hub',
  readChainRegionMdHub = 'read_chain_region_md_hub',
  readFtSkuMdHub = 'read_ft_sku_md_hub',
  readFinanceHub = 'read_finance_hub',
  readPriceListHub = 'read_price_list_hub',
  readPromoGuideHub = 'read_promo_guide_hub',
  readWdShelfRecoPriceHub = 'read_wd_shelf_reco_price_hub',
  readNoBlRecalculationHub = 'read_no_bl_recalculation_hub',
  readPromoVolumeHub = 'read_promo_volume_hub',
  readPromoTemplateHub = 'read_promo_template_hub',
  readPmdStatusHub = 'read_pmd_status_hub',
  readCapacityMdHub = 'read_capacity_md_hub',
  readProductMd = 'read_product_md',
  readCustomerMdBasic = 'read_customer_md_basic',
  readCustomerMdBasicExtended = 'read_customer_md_basic_extended',
  readSalesHistory = 'read_sales_history',
  readAdfQuarterly = 'read_adf_quarterly',
  readAdfBiWeekly = 'read_adf_bi_weekly',
  promoCalculateCreateDashboard = 'promo_calculate_create_dashboard',
}
