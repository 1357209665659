import { axios } from 'config/axios'
import { GetOneResponse } from 'interfaces/api.interfaces'

import { systemUpdatingEndpoints } from './endpoints'
import { SystemUpdating } from './interfaces'

const getSystemUpdatingStatus = async (): GetOneResponse<SystemUpdating> => {
  const { data } = await axios.request<SystemUpdating>({
    method: 'GET',
    url: systemUpdatingEndpoints.getUpdatingStatus(),
  })
  return data
}

export const systemUpdatingApi = {
  getSystemUpdatingStatus,
}
