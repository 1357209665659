import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import cx from 'clsx'
import { useSizeEqual } from 'hooks/useSizeEqual'
import { Tooltip } from 'ui/Tooltip'

import classes from './Truncate.module.scss'

interface TruncateProps {
  className?: string
  classNameDropdown?: string
  children?: ReactNode
  enabled?: boolean
  disabledTooltip?: boolean
  lines?: number
  maxWidth?: number
  container?: (children: ReactNode) => ReactNode
  offsetX?: number
  isWordBreakAll?: boolean
  position?: 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight'
}

export const Truncate: FC<TruncateProps> = ({
  className,
  classNameDropdown,
  children,
  enabled = true,
  disabledTooltip,
  lines = 1,
  maxWidth,
  container = (node) => node,
  offsetX,
  isWordBreakAll,
  position,
}) => {
  const [enabledInternal, setEnabledInternal] = useState(enabled)

  const ref1 = useRef<HTMLDivElement>(null)
  const ref2 = useRef<HTMLDivElement>(null)

  const onChangeSizeEqual = useCallback((equal: boolean) => {
    if (enabled) {
      setEnabledInternal(!equal)
    }
  }, [])

  useSizeEqual(ref1, ref2, onChangeSizeEqual)

  useEffect(() => {
    setEnabledInternal(enabled)
  }, [enabled])

  return (
    <>
      <Tooltip
        className={cx(classes.wrap, className, { truncateEllipsis: enabledInternal })}
        classNameContainer={classes.cont}
        classNameDropdown={cx(classNameDropdown, { [classes.wordBreakAll]: isWordBreakAll })}
        cursorPointer={false}
        disabled={disabledTooltip || !enabledInternal}
        isHovered
        offsetX={offsetX}
        position={position}
        style={
          enabled
            ? {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: lines,
                WebkitBoxOrient: 'vertical',
              }
            : undefined
        }
        styleContainer={{ maxWidth }}
        tooltip={children}
      >
        <div className={cx({ truncate: lines === 1 })} ref={ref1}>
          {container(children)}
        </div>
      </Tooltip>
      {!disabledTooltip && (
        <div className={cx(classes.hide, className)} style={lines === 1 ? { minWidth: 'max-content' } : undefined}>
          <div ref={ref2}>{container(children)}</div>
        </div>
      )}
    </>
  )
}
