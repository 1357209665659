import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/gitlab-deploy-status'

interface Endpoints {
  getUpdatingStatus: NoParamEndpointConstructor
}

export const systemUpdatingEndpoints: Endpoints = {
  getUpdatingStatus: () => baseUrl,
}
