import { useCallback } from 'react'
import { useBeforeUnload, unstable_usePrompt as usePrompt } from 'react-router-dom'

import { useTranslate } from 'config/i18n'

export const useUnsavedChanges = (isDirty: boolean, message?: string) => {
  const translate = useTranslate()
  const unsavedMessage = message || translate('unsavedChangesWarning')

  useBeforeUnload(
    useCallback(
      (event) => {
        if (isDirty) {
          event.preventDefault()
          event.returnValue = message
          return message
        }
      },
      [isDirty],
    ),
  )

  usePrompt({ when: isDirty, message: unsavedMessage })
}
