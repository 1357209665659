import { FC, useState } from 'react'

import { useSearchProject } from 'modules/projects/queries'
import { useProfile, useSwitchProject } from 'modules/user/queries'
import { Select } from 'ui/Select'

interface ProjectSelectProps {
  className?: string
}

export const ProjectSelect: FC<ProjectSelectProps> = ({ className }) => {
  const [searchValue, setSearchValue] = useState('')

  const { projects, isLoading: isLoadingProject, hasNextPage, fetchNextPage } = useSearchProject(searchValue)
  const { profile } = useProfile()
  const { switchProject, isLoadingSwitchingProject } = useSwitchProject()

  const isLoading = isLoadingProject || isLoadingSwitchingProject

  return (
    <Select
      className={className}
      hasNextPage={hasNextPage}
      isOptionEllipsis
      loading={isLoading}
      onChange={switchProject}
      onScrollPaginate={fetchNextPage}
      onSearch={setSearchValue}
      options={projects.map((item) => ({
        value: item.id,
        label: item ? item.title : '',
      }))}
      showSearch
      value={!isLoadingProject ? profile?.activeProjectId : undefined}
    />
  )
}
