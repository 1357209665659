import { forwardRef, MouseEventHandler, ReactNode, useEffect, useState } from 'react'

import { ReactComponent as InfoIcon } from 'assets/images/info.svg'
import { ReactComponent as UpdateIcon } from 'assets/images/update.svg'
import cx from 'clsx'
import { TextModal } from 'components/modals/TextModal'
import { useTranslate } from 'config/i18n'
import { useModal } from 'hooks/useModal'
import { ButtonHTMLTypes } from 'interfaces/components.interfaces'
import { Loader, LoaderColors } from 'ui/Loader'

import classes from './Button.module.scss'

export enum ButtonColors {
  Primary = 'primary',
  Danger = 'danger',
  White = 'white',
  Border = 'border',
  BorderPrimary = 'borderPrimary',
  BorderWhite = 'borderWhite',
  Text = 'text',
  TextPrimary = 'textPrimary',
  TextDanger = 'textDanger',
}

const LoaderDependence = {
  [ButtonColors.Primary]: LoaderColors.White,
  [ButtonColors.Danger]: LoaderColors.White,
  [ButtonColors.White]: LoaderColors.Gray,
  [ButtonColors.Border]: LoaderColors.Gray,
  [ButtonColors.BorderPrimary]: LoaderColors.Gray,
  [ButtonColors.BorderWhite]: LoaderColors.White,
  [ButtonColors.Text]: LoaderColors.Gray,
  [ButtonColors.TextPrimary]: LoaderColors.Default,
  [ButtonColors.TextDanger]: LoaderColors.Danger,
}

export enum ButtonSizes {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

interface ButtonProps {
  className?: string
  color?: ButtonColors
  size?: ButtonSizes
  fullWidth?: boolean
  onClick?: MouseEventHandler
  disabled?: boolean
  unclickable?: boolean
  loading?: boolean
  htmlType?: ButtonHTMLTypes
  children?: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
  stopPropagation?: boolean
  error?: ReactNode
  errorTitle?: string | null
  onRepeat?: () => void
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      color = ButtonColors.Primary,
      size = ButtonSizes.Large,
      fullWidth,
      children,
      onClick,
      disabled,
      unclickable,
      loading,
      htmlType = 'button',
      startIcon,
      endIcon,
      stopPropagation,
      error,
      errorTitle,
      onRepeat,
    },
    ref,
  ) => {
    const [loadingInternal, setLoadingInternal] = useState(false)
    const [errorInternal, setErrorInternal] = useState(error)
    const [renderForError, setRenderForError] = useState(!!error)
    const [openError, onOpenError, onCloseError] = useModal()
    const translate = useTranslate()
    const isRepeatable = errorInternal && onRepeat

    const onClickInternal: MouseEventHandler = (e) => {
      if (stopPropagation) {
        e.stopPropagation()
      }
      if (errorInternal) {
        return onOpenError()
      }
      if (onClick) {
        onClick(e)
      }
    }

    useEffect(() => {
      setLoadingInternal(!!loading)
    }, [loading])

    useEffect(() => {
      if (error) {
        setErrorInternal(error)
        setRenderForError(true)
      } else if (!loading) {
        setErrorInternal(undefined)
        setRenderForError(false)
      }
    }, [error, loading])

    useEffect(() => {
      if (renderForError) {
        requestAnimationFrame(() => setRenderForError(false))
      }
    }, [renderForError])

    if (errorInternal) {
      children = translate('error')
      startIcon = <InfoIcon />
      endIcon = undefined
      color = ButtonColors.Danger
    }

    return (
      <>
        <button
          className={cx(classes.button, className, classes[color], classes[size], {
            [classes.full]: fullWidth,
            [classes.loading]: loadingInternal && !isRepeatable,
            [classes.unclickable]: unclickable,
            [classes.withoutChildren]: !children,
            [classes.renderForError]: renderForError,
          })}
          disabled={disabled || unclickable}
          onClick={onClickInternal}
          ref={ref}
          type={htmlType}
        >
          <span className={classes.wrapper}>
            {loadingInternal && !isRepeatable && <Loader className={classes.loader} color={LoaderDependence[color]} />}
            <span className={classes.cont}>
              {startIcon && <div className={cx(classes.icon, classes.left)}>{startIcon}</div>}
              {children}
              {endIcon && <div className={cx(classes.icon, classes.right)}>{endIcon}</div>}
            </span>
          </span>
          {isRepeatable && (
            <div
              className={cx(classes.repeat, { [classes.isLoadingRepeat]: loadingInternal })}
              onClick={(event) => {
                event.stopPropagation()
                onRepeat()
              }}
            >
              <UpdateIcon />
            </div>
          )}
        </button>
        {errorInternal && (
          <TextModal isOpened={openError} onClose={onCloseError} title={errorTitle ?? translate('saveError')}>
            {errorInternal}
          </TextModal>
        )}
      </>
    )
  },
)
