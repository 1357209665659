import { MasterDataHierarchyLevel, MasterDataHierarchyLevelBase } from 'jume/masterData/api'

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never

export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

export interface Prototype {
  prototype: any
}

export interface Range {
  min: number
  max: number
}

export interface Status<Code = string> {
  id: number
  name: string
  code: Code
}

export interface LevelElement {
  id: number | null
  code: string | null
  title: string
  levelId?: number
}

export interface HierarchyTree {
  id: number
  title: string
  code: string
  childrenCount?: number
  children?: HierarchyTree[]
  parentId?: number
  parentIds?: number[]
}

export interface MasterDataHierarchyItem {
  id: number
  name: string
  forecastLevel: MasterDataHierarchyLevelBase
  levels: MasterDataHierarchyLevel[]
  updated: string
  isActive: boolean
  uploadDisabledReason: string
  uploadEnabled: boolean
  isProduct?: boolean
  isLoadedElements?: boolean
  isInProcess?: boolean
  processingError?: string | null
}

export enum WarningColors {
  Dark = 'dark',
  Danger = 'danger',
  Primary = 'primary',
}

export type RequiredFields<T, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>
export type PartialFields<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>

export interface CombinedFilterState {
  include?: (string | number)[]
  exclude?: (string | number)[]
  showTotal?: boolean
}
