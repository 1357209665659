import { RefObject, useEffect } from 'react'

export const useSyncWidth = (
  el?: RefObject<HTMLElement>,
  elDependent?: RefObject<HTMLElement> | null,
  offset = 0,
  deps?: any[],
  enabled = true,
) => {
  useEffect(() => {
    if (!el?.current || !elDependent?.current || !enabled) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { clientWidth } = entry.target
        if (elDependent.current) {
          elDependent.current.style.width = `${clientWidth + offset}px`
        }
      })
    })
    resizeObserver.observe(el.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [el?.current, elDependent?.current, enabled, ...(deps || [])])
}
