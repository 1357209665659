import { useEffect, useRef } from 'react'

import { Subscription } from 'centrifuge'
import { getCentrifuge } from 'config/centrifuge'
import { useProfile } from 'modules/user/queries'
import { toCamelCase } from 'packages/helper'

export const useWs = (
  channel: string,
  options?: { enabled?: boolean; onPublish?: Record<string | 'default', (data: any, response?: any) => void> },
) => {
  const { profile } = useProfile()
  const refSubscription = useRef<Subscription | null>(null)
  const enabled = !!profile && (options?.enabled ?? true)

  useEffect(() => {
    const centrifuge = getCentrifuge()
    if (!centrifuge) {
      return
    }
    if (!enabled) {
      return () => {
        if (refSubscription.current) {
          refSubscription.current.unsubscribe()
          centrifuge.removeSubscription(refSubscription.current)
        }
      }
    }
    const sub = centrifuge.newSubscription(channel)
    refSubscription.current = sub
    sub.subscribe()

    sub.on('publication', function (ctx) {
      if (ctx.data === undefined) {
        return
      }
      options?.onPublish?.[ctx.data?.type || 'default']?.(ctx.data?.data ?? ctx.data?.value, toCamelCase(ctx.data))
    })

    if (centrifuge.state !== 'connected') {
      centrifuge.connect()
    }

    return () => {
      sub.unsubscribe()
      centrifuge.removeSubscription(sub)
    }
  }, [enabled])
}
