import { Get } from 'interfaces/tanstackQuery.interfaces'
import { FilterError, UseErrors } from 'modules/filters/common/interfaes'
import { Mutate, StoreApi } from 'zustand'

export const generateErrorsState = <Error extends FilterError = FilterError>(
  set: Get<Mutate<StoreApi<UseErrors<Error>>, []>, 'setState', never>,
  get: () => UseErrors<Error>,
): UseErrors<Error> => ({
  errors: [],
  clear: () => set({ errors: [] }),
  setErrors: (errors) => set({ errors }),
  removeError: (tab, group, index, key) =>
    set(({ errors }) => ({
      errors: errors
        .filter((item) => item.index !== index || item.group !== group || item.key !== key || item.tab !== tab)
        .map((item) => ({
          ...item,
          index:
            typeof item.index !== 'undefined' && typeof index !== 'undefined' && item.index >= index && item.key === key
              ? item.index - 1
              : item.index,
        })),
    })),
  removeByIndex: (tab, index) =>
    set(({ errors }) => ({
      errors: errors.filter((item) => item.index !== index || item.tab !== tab),
    })),
  removeByIndexAndShift: (tab, index) =>
    set(({ errors }) => ({
      errors: errors
        .filter((item) => item.index !== index || item.tab !== tab)
        .map((item) => ({
          ...item,
          index:
            typeof item.index !== 'undefined' && typeof index !== 'undefined' && item.index >= index
              ? item.index - 1
              : item.index,
        })),
    })),
  removeByGroup: (tab, group) =>
    set(({ errors }) => ({ errors: errors.filter((item) => item.group !== group || item.tab !== tab) })),
  removeByKey: (tab, key) =>
    set(({ errors }) => ({ errors: errors.filter((item) => item.key !== key || item.tab !== tab) })),
  findError: (tab, group, index, key) => {
    const { errors, removeError } = get()
    return {
      error: errors.find(
        (item) => item.index === index && item.group === group && item.tab === tab && item.key === key,
      ),
      removeError: () => removeError(tab, group, index, key),
    }
  },
})
