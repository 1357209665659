import { OptionTreeItem } from 'interfaces/components.interfaces'

interface ParseValueProps<ValueType extends string | number | (number | string)[], Meta = unknown> {
  valueString?: string
  valueStringWhenNotLoaded?: string
  value?: ValueType | null
  valueInternal?: ValueType | null
  options?: OptionTreeItem<Meta>[]
  multiple?: boolean
  isTree?: boolean
}

export const parseValue = <ValueType extends string | number | (number | string)[], Meta = unknown>({
  valueString,
  valueStringWhenNotLoaded,
  value,
  valueInternal,
  options,
  multiple,
  isTree,
}: ParseValueProps<ValueType, Meta>) => {
  if (valueString !== undefined && valueString !== null) {
    return valueString as ValueType
  }

  if (
    !multiple &&
    !isTree &&
    !valueStringWhenNotLoaded &&
    valueInternal === undefined &&
    value !== undefined &&
    options !== undefined
  ) {
    return options?.find((item) => item.value === value)?.value as ValueType
  }

  if (!multiple && !isTree && valueStringWhenNotLoaded && !options?.find((item) => item.value === valueInternal)) {
    return valueStringWhenNotLoaded as ValueType
  }

  return valueInternal
}
