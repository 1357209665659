import { FC, JSX, ReactElement, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useProjectType } from 'hooks/useProjectType'
import { useBroadcastAuth } from 'modules/auth/handlers/useBroadcastAuth'
import { useClearSearchParams } from 'modules/auth/handlers/useClearSearchParams'
import { useProject, useProjectInfo } from 'modules/projects/queries'
import { useLastSession } from 'modules/session/store'
import { usePermissions, useProfile } from 'modules/user/queries'
import { Routes } from 'routes/routes'
import { ErrorStack } from 'ui/ErrorStack'

export function withAuth<T = any>(Component: FC<T>) {
  return (props: JSX.IntrinsicAttributes & T): ReactElement => {
    const { isLoadingProfile, authenticated, profileError } = useProfile()
    const setLastPage = useLastSession((state) => state.setPage)
    const { isLoadingProject } = useProject()
    const { isLoadingProjectInfo } = useProjectInfo()
    const { isLoadingPermissions } = usePermissions()
    const isLoading =
      !authenticated ||
      isLoadingProfile ||
      isLoadingProject ||
      isLoadingProjectInfo ||
      isLoadingPermissions ||
      (props as any).loading
    const location = useLocation()

    const { isRedirecting } = useProjectType()
    const navigate = useNavigate()
    const { ready } = useBroadcastAuth()
    useClearSearchParams()

    useEffect(() => {
      if (ready && !authenticated) {
        setLastPage(location.pathname)
        navigate(Routes.Login)
      }
    }, [ready, authenticated])

    if (profileError) {
      return <ErrorStack classNameWrap="center" errors={profileError} />
    }

    return <Component {...props} loading={isLoading || isRedirecting} />
  }
}
